import { defaultAddressObj } from '../../utils/address';

export const mapOptions = {
  panControl: false,
  streetViewControl: false,
  scaleControl: false,
  fullscreenControl: false,
  rotateControl: false,
  tiltControl: false,
  mapTypeControl: false,
  zoomControl: false,
  tilt: 0,
  disableDoubleClickZoom: true,
  clickableIcons: false
};

function initAddressConversion(firstChoice) {
  const typeObj = {
    street_number: '',
    establishment: '',
    route: '',
    postal_town: '',
    neighborhood: '',
    sublocality: '',
    locality: '',
    administrative_area_level_1: '',
    administrative_area_level_2: '',
    administrative_area_level_3: '',
    administrative_area_level_4: '',
    country: '',
    postal_code: ''
  };

  // turn array into obj - safeguard against unordered array
  firstChoice.address_components.forEach(comp => {
    const typeObjArr = Object.keys(typeObj);
    for (let i = 0; i < typeObjArr.length; i++) {
      if (comp.types.includes(typeObjArr[i])) {
        // needed due to country code for backend
        if (typeObjArr[i] === 'country') {
          typeObj[typeObjArr[i]] = comp.short_name;
        } else {
          typeObj[typeObjArr[i]] = comp.long_name;
        }
        break;
      }
    }
  });

  return typeObj;
}

export function isNumberPresent(firstChoice) {
  const typeObj = initAddressConversion(firstChoice);
  return !typeObj.street_number;
}

export function getAddressObj(firstChoice) {
  const addressObj = { ...defaultAddressObj };
  const typeObj = initAddressConversion(firstChoice);

  // Administrative Levels
  for (let i = 0; i < 4; i++) {
    const adminLevel = typeObj[`administrative_area_level_${i + 1}`];
    if (adminLevel) {
      addressObj.adminLevels.push(adminLevel);
    } else break;
  }

  // Route
  if (typeObj.street_number) addressObj.route = `${typeObj.street_number} `;

  if (typeObj.establishment) addressObj.route += typeObj.establishment;
  else if (typeObj.route) {
    addressObj.route += typeObj.route;
  }

  // City
  // Priority list: locality, postal town, sublocality, neiborhood
  addressObj.city =
    typeObj.locality ||
    typeObj.postal_town ||
    typeObj.sublocality ||
    typeObj.neighborhood;

  // State
  addressObj.state = typeObj.administrative_area_level_1;

  // County
  addressObj.county = typeObj.administrative_area_level_2;

  // Country
  addressObj.country = typeObj.country;

  // Postal Code
  addressObj.postal_code = typeObj.postal_code;

  // HARD_CODE: State work around for UK
  if (addressObj.country === 'UK' || addressObj.country === 'GB') {
    addressObj.state = addressObj.county;
    addressObj.county = '';
  }
  return addressObj;
}

export function isFullAddress(data) {
  return !!data.route;
}
