export const defaultAddressObj = {
  route: '',
  route2: '',
  city: '',
  state: '',
  country: '',
  postal_code: '',
  county: '',
  adminLevels: []
};

export const defaultAddressAndPosition = {
  center: {
    lat: null,
    lng: null
  },
  addressObj: defaultAddressObj
};

export function getAddressForServer(state) {
  return {
    address1: state.addressObj.route,
    address2: state.addressObj.route2,
    city: state.addressObj.city,
    state: state.addressObj.state,
    country: state.addressObj.country,
    zip: state.addressObj.postal_code,
    address_lat: state.center.lat,
    address_lon: state.center.lng,
    show_street_warning: state.showStreetWarning,
    administrative_levels: state.addressObj.adminLevels
  };
}
