import React, { Fragment } from 'react';
import './style.css';
import { useTranslation } from 'react-i18next';

function ErrorMessage(props) {
  const { t } = useTranslation();
  return (
    <Fragment>
      {props.display && (
        <p className='error'>
          {props.errFromValidation
            ? t(props.err)
            : t(`error_server.${props.err}`)}
        </p>
      )}
    </Fragment>
  );
}

export default ErrorMessage;
