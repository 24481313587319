import { fetchGET } from '../../Router/helpers';

export function getCustomAddress(data) {
  return {
    route: data.address1,
    route2: data.address2,
    city: data.city,
    state: data.state.name,
    country: data.country.code,
    postal_code: data.postal
  };
}

let countryList = [];
// have country list saved in memory
export function getCountryList() {
  return new Promise(resolve => {
    if (countryList.length === 0) {
      fetchGET('/installer/country').then(data => {
        countryList = data;
        resolve(data);
      });
    } else {
      resolve(countryList);
    }
  });
}

const stateList = {};
// have state list saved in memory
export function getStateList(countryID) {
  return new Promise(resolve => {
    if (!stateList[countryID]) {
      fetchGET(`/installer/state/${countryID}`).then(res => {
        stateList[countryID] = res;
        resolve(res);
      });
    } else {
      resolve(stateList[countryID]);
    }
  });
}
