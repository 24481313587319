export function formatBytes(bytes, decimals) {
  if (bytes === 0) return '0 Bytes';
  const k = 1000;

  const dm = decimals <= 0 ? 0 : decimals || 2;

  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

  const i = Math.floor(Math.log(bytes) / Math.log(k));
  return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`;
}

export function getFile(img) {
  return new Promise(resolve => {
    fetch(img)
      .then(res => res.blob())
      .then(blob => {
        const nameSplit = img.split('/');
        const name = nameSplit[nameSplit.length - 1];
        const file = new File([blob], name, blob);
        resolve(file);
      });
  });
}
