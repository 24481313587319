import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import Auth from '../../Router/Auth';
import LoadingIndicator  from '../../Components/LoadingIndicator';
import Cookies from 'universal-cookie';

class BetaLogin extends Component {
  constructor(props) {
    super(props)
    const betaData = {
      defaultmac: '',
      defaultmacpass: '',
      betacoulombsess: '',
      betaciuisession: '',
      betasubdomain: ''
    }

    if (props.match && props.match.params) {
      if (props.match.params.defaultmac) {
        betaData.defaultmac = props.match.params.defaultmac
      }
      if (props.match.params.defaultmacpass) {
        betaData.defaultmacpass = props.match.params.defaultmacpass
      }
    }

    this.state = {
      ...betaData,
      isValidatingBetaUser: true,
      isBetaUserValid: false
    }
  }

  static getDerivedStateFromProps(props, state) {
    let newState = state
    const cookies = new Cookies()
    newState.betacoulombsess = cookies.get('beta_coulomb_sess', { path: '/b' })
    newState.betaciuisession = cookies.get('beta_ci_ui_session', { path: '/b' })
    newState.betasubdomain = cookies.get('beta_subdomain', { path: '/b' })

    if (newState.isValidatingBetaUser) {
      if (newState.betacoulombsess && newState.betaciuisession && newState.betasubdomain) {
        newState.isBetaUserValid = false
      } else {
        newState.isValidatingBetaUser = false
        newState.isBetaUserValid = false
      }
    }
    return newState
  }

  componentDidMount() {
    if (this.state.isValidatingBetaUser) {
      Auth.signout()
        .then(res => {
          if (res.status === 200) {
            Auth.signinBeta(this.state.betacoulombsess, this.state.betaciuisession, this.state.betasubdomain)
              .then(() => {
                this.setState({
                  isValidatingBetaUser: false,
                  isBetaUserValid: true
                })
              })
              .catch(err => {
                this.setState({
                  isValidatingBetaUser: false,
                  isBetaUserValid: false
                })
              })
          } else {
            this.setState({
              isValidatingBetaUser: false,
              isBetaUserValid: false
            })
          }
        })
    }
  }

  render() {
    const betaValidatedRedirectUrl = '/i/' + this.state.defaultmac + '/' + this.state.defaultmacpass 
    return (
      <div>
          {
            this.state.isValidatingBetaUser ? (
              <LoadingIndicator
                size={50}
                show={true}/>
              ) : (
                this.state.isBetaUserValid ? <Redirect to={betaValidatedRedirectUrl} /> : <Redirect to='/login' />
              )
          }
      </div>
    )
  }
}

export default BetaLogin