const phaseConnectionDisplayMap = {
    '1': 'PE - - L1 N',
    '2': 'PE - - L2 N',
    '3': 'PE - - L3 N',
    '1-2-3': 'PE L1 L2 L3 N',
    '3-1-2': 'PE L3 L1 L2 N',
    '2-3-1': 'PE L2 L3 L1 N'
}

export function formatPhaseConnectionsListForDisplay(phaseConnectionsList) {
    const phaseConnectionsListForDisplay = []
    phaseConnectionsList.forEach(phaseConnection => {
        phaseConnectionsListForDisplay.push(phaseConnectionDisplayMap[phaseConnection])
    })
    return phaseConnectionsListForDisplay
}

export function formatPhaseConnection(phaseConnection) {
    const result = [];
    phaseConnection.forEach(phase => {
        for (let phaseConnectionProp in phaseConnectionDisplayMap) {
            if (phaseConnectionDisplayMap[phaseConnectionProp] === phase) {
                result.push(phaseConnectionProp);
                break;
            }
        }
    });
    return  result;
}