import React, {Component, Fragment} from 'react';
import Radio from '../../Components/Radio';
import Navigation from '../../Components/Navigation';
import './style.css';

class InstallationType extends Component {
  constructor(props) {
    super(props);
    const uiState = {
      installtype: 'new'
    };

    if (props.savedData) {
      Object.keys(uiState).forEach(key => {
        if (props.savedData[key]) {
          uiState[key] = props.savedData[key];
        }
      });
    }

    this.state = {...uiState, modelName: props.global.modelName || ''};
    this.onChange = this.onChange.bind(this);
  }

  onChange(value) {
    this.setState({installtype: value});
  }

  render() {
    const {t} = this.props;

    return (
      <Fragment>
        <p>{t('installationtype.paragraph_intro')}</p>
        <Radio
          onSelected={this.onChange}
          selectedOption={this.state.installtype}
          labels={[
            {
              text: t('installationtype.radiobuttons_new', {
                model: this.state.modelName
              }),
              key: 'new'
            },
            {
              text: t('installationtype.radiobuttons_replace', {
                model: this.state.modelName
              }),
              key: 'replace'
            }
          ]}
        />

        <Navigation data={{installtype: this.state.installtype}}/>
      </Fragment>
    );
  }
}

export default InstallationType;
