export const wizardDefaultState = {
  savedData: {},
  globalData: {
    userCoords: { latitude: 0, longitude: 0 }
  }
};

export function setPortADA(value) {
  const obj = {};
  let ADA = value;
  if (typeof value === 'string') {
    ADA = JSON.parse(value);
  }
  obj.adaResult = ADA;
  if (Object.keys(ADA).length > 0) {
    obj.parkingDual = !ADA.single;
  } else {
    obj.noParking = true;
  }
  return obj;
}

export function formatDefaultMac(params) {
  let defaultmacpass = params.defaultmacpass;
  let installtype = '';
  if (defaultmacpass[defaultmacpass.length - 1] === 'N') {
    installtype = 'new';
    defaultmacpass = params.defaultmacpass.slice(0, -1);
  }
  if (defaultmacpass[defaultmacpass.length - 1] === 'H') {
    installtype = 'replace';
    defaultmacpass = params.defaultmacpass.slice(0, -1);
  }
  const formattedObj = {
    defaultmac: params.defaultmac,
    defaultmacpass,
    installtype
  };
  return formattedObj;
}

export function setPinpointData(prevdata, data) {
  return {
    '/pinpoint': {
      ...prevdata,
      stationCenter: data.center
    }
  };
}

export function setAddressFormData(data) {
  return {
    '/addressform': {
      ...data
    }
  };
}
