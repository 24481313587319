import { setPortADA } from './helpers';
import { formatPhaseConnectionsListForDisplay } from '../../Pages/PhaseConnection/helpers'

function formattedAddress(obj) {
  if (obj.address1 !== null && obj.city !== null) {
    return `${obj.address1}, ${obj.city}, ${obj.state}, ${obj.country}`;
  }

  return '';
}

export function setPageData(obj) {
  const newObj = {
    '/address': {
      addressObj: {},
      center: {}
    },
    '/makemodel': {},
    '/phaseconnection': {},
    '/pinpoint': {
      stationCenter: {},
      center: {},
      prevCenter: {}
    },
    '/stationdetails': {},
    '/stationphoto': {},
    '/terminalblock': {}
  };

  Object.entries(obj).forEach(entry => {
    const value = entry[1];
    switch (entry[0]) {
      // Make & Model Page (OCPP)
      case 'make_id':
        newObj['/makemodel'].makeID = value;
        break;
      case 'model_id':
        newObj['/makemodel'].modelID = value;
        break;
      case 'serial_number':
        newObj['/makemodel'].stationSerial = value;
        break;
      case 'make_name':
        newObj['/makemodel'].makeName = value;
        break;
      case 'model_number':
        newObj['/makemodel'].modelName = value;
        break;
      case 'panel_phase_connection':
        newObj['/phaseconnection'].phaseConnection = formatPhaseConnectionsListForDisplay([value])[0];
        break;
      case 'address1':
        newObj['/address'].addressObj.route = value;
        break;
      case 'address2':
        newObj['/address'].addressObj.route2 = value;
        break;
      case 'city':
        newObj['/address'].addressObj.city = value;
        break;
      case 'state':
        newObj['/address'].addressObj.state = value;
        break;
      case 'country':
        newObj['/address'].addressObj.country = value;
        break;
      case 'administrative_levels':
        newObj['/address'].addressObj.adminLevels = value;
        break;

      case 'zip':
        newObj['/address'].addressObj.postal_code = value;
        break;
      case 'show_street_warning':
        newObj['/address'].showStreetWarning = value;
        break;

      case 'address_lat':
        newObj['/address'].center.lat = value;
        newObj['/pinpoint'].stationCenter.lat = value;
        break;
      case 'address_lon':
        newObj['/address'].center.lng = value;
        newObj['/pinpoint'].stationCenter.lng = value;
        break;

      case 'station_lat':
        newObj['/pinpoint'].center.lat = value;
        break;
      case 'station_lon':
        newObj['/pinpoint'].center.lng = value;
        break;

      case 'parking_lot':
        newObj['/stationdetails'].pLot = value;
        break;
      case 'parking_building':
        newObj['/stationdetails'].pBuilding = value;
        break;
      case 'parking_label':
        newObj['/stationdetails'].pLabel = value;
        break;
      case 'parking_ada':
        newObj['/stationdetails'] = {
          ...newObj['/stationdetails'],
          ...setPortADA(value)
        };
        break;

      case 'station_info':
        newObj['/stationphoto'].info = value;
        break;
      case 'station_image_url':
        newObj['/stationphoto'].fileImage = value;
        break;

      case 'terminal_block_image_url':
        newObj['/terminalblock'].fileImage = value;
        break;

      default:
        break;
    }
  });

  newObj['/address'].searchBoxValue = formattedAddress(obj);

  return newObj;
}
