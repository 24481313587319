// EE_TODO: abstract validations with observables

import React, { Component } from 'react';
import SubmitContext from './SubmitContext';

class FormValidation extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pressedSubmit: false
    };

    this.formSubmit = this.formSubmit.bind(this);

    this.getValidateFunction = this.getValidateFunction.bind(this);

    this.childValidatefn = {};
    this.masterValidate = this.masterValidate.bind(this);

    this.getValidationResult = this.getValidationResult.bind(this);
    this.masterValidationResult = this.masterValidationResult.bind(this);
    this.childValidationResult = {};
    this.passedValidation = false;

    this.getResetFunction = this.getResetFunction.bind(this);
    this.masterReset = this.masterReset.bind(this);

    this.childResetfn = {};

    this.childDataResult = {};

    this.unmountChild = this.unmountChild.bind(this);
  }

  getValidateFunction(key, valfn) {
    this.childValidatefn[key] = valfn;
  }

  getValidationResult(resObj, val) {
    const key = Object.keys(resObj)[0];
    this.childValidationResult[key] = resObj[key];
    this.childDataResult[key] = val;
  }

  getResetFunction(key, resetfn) {
    this.childResetfn[key] = resetfn;
  }

  masterValidate() {
    Object.keys(this.childValidatefn).forEach(key => {
      this.childValidatefn[key]();
    });
  }

  masterValidationResult() {
    this.passedValidation = Object.keys(this.childValidationResult).every(
      key => {
        return this.childValidationResult[key];
      }
    );
  }

  masterReset() {
    Object.keys(this.childResetfn).forEach(key => this.childResetfn[key]());
    if (this.props.onReset) {
      this.props.onReset();
    }
  }

  unmountChild(comp) {
    delete this.childValidatefn[comp.props.name];
    delete this.childResetfn[comp.props.name];
    delete this.childValidationResult[comp.props.name];
    delete this.childDataResult[comp.props.name];
  }

  formSubmit(e) {
    e.preventDefault();

    this.masterValidate();

    this.setState({ pressedSubmit: true });

    this.masterValidationResult();

    if (this.passedValidation) {
      this.props.onSubmit(this.childDataResult);
    }
  }

  render() {
    return (
      <SubmitContext.Provider
        value={{
          provideValidateFunction: this.getValidateFunction,
          provideValidationResult: this.getValidationResult,
          provideData: this.provideData,
          pressedSubmit: this.state.pressedSubmit,
          provideResetFunction: this.getResetFunction,
          unmountFunction: this.unmountChild
        }}
      >
        <form onSubmit={this.formSubmit} onReset={this.masterReset} noValidate>
          {this.props.children}
        </form>
      </SubmitContext.Provider>
    );
  }
}

export default FormValidation;
