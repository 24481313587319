import React, { Component, Fragment } from 'react';
import './style.css';
import FormValidation from '../../Components/FormValidation';
import Input from '../../Components/Input';
import Dropdown from '../../Components/Dropdown';
import Navigation from '../../Components/Navigation';
import ErrorMessage from '../../Components/ErrorMessage';
import {
  defaultAddressAndPosition,
  getAddressForServer
} from '../../utils/address';
import { fetchAPI } from '../../Router/helpers';
import { getCountryList, getStateList, getCustomAddress } from './helpers';

class AddressForm extends Component {
  constructor(props) {
    super(props);
    const uiState = {
      ...defaultAddressAndPosition,
      showStreetWarning: false,
      chosenState: ''
    };

    if (props.savedData) {
      Object.keys(uiState).forEach(key => {
        if (props.savedData[key]) {
          uiState[key] = props.savedData[key];
        }
      });
      uiState.chosenState = props.savedData.addressObj.state;
    }

    this.state = {
      countryList: [],
      stateList: [],
      data: {},
      chosenCountry: '',
      isValidated: false,
      displayError: false,
      errorMessage: '',
      errFromValidation: false,
      ...uiState
    };

    this.onSubmit = this.onSubmit.bind(this);
    this.retrieveCountry = this.retrieveCountry.bind(this);
    this.retrieveState = this.retrieveState.bind(this);
    this.pageApiCall = this.pageApiCall.bind(this);
  }

  async componentDidMount() {
    // Get Country
    const countryList = await getCountryList();
    this.setState({
      countryList
    });

    // Prefill and set presaved data
    countryList.forEach(async country => {
      if (
        country.code.toLowerCase() ===
        this.state.addressObj.country.toLowerCase()
      ) {
        const stateList = await getStateList(country.id);
        this.setState(prevState => {
          const newState = {
            stateList,
            addressObj: {
              ...prevState.addressObj,
              country: country.code
            },
            chosenCountry: country.name,
            chosenState: prevState.chosenState
          };

          if (
            !stateList.find(
              st =>
                st.name.toLowerCase() ===
                prevState.addressObj.state.toLowerCase()
            )
          ) {
            newState.chosenState = 'initial';
            newState.addressObj.state = '';
          }
          return newState;
        });
      }
    });
  }

  async retrieveCountry(data) {
    const stateList = await getStateList(data.id);
    this.setState(prevState => ({
      stateList,
      addressObj: {
        ...prevState.addressObj,
        country: data.code,
        state: ''
      },
      chosenCountry: data.name,
      chosenState: 'initial'
    }));
  }

  retrieveState(data) {
    this.setState(prevState => ({
      addressObj: {
        ...prevState.addressObj,
        state: data.name
      },
      chosenState: data.name
    }));
  }

  onSubmit(data) {
    const addressObj = getCustomAddress(data);
    this.setState({
      addressObj,
      isValidated: true
    });
  }

  pageApiCall() {
    return new Promise((resolve, reject) => {
      const formData = getAddressForServer(this.state);
      fetchAPI(
        `/installer/station_address/${this.props.global.deviceId}`,
        formData
      )
        .then(res => {
          if (!res.error) {
            this.setState(
              prevState => ({
                data: {
                  addressObj: prevState.addressObj,
                  center: prevState.center,
                  pageSaved: true,
                  showStreetWarning: prevState.showStreetWarning
                }
              }),
              () => {
                resolve();
              }
            );
          } else {
            throw Error(res.message);
          }
        })
        .catch(err => {
          this.setState(
            {
              displayError: true,
              errorMessage: err.message,
              isValidated: false,
              errFromValidation: false
            },
            () => {
              reject();
            }
          );
        });
    });
  }

  render() {
    const { t } = this.props;
    return (
      <Fragment>
        <FormValidation onSubmit={this.onSubmit}>
          <div id='address-form'>
            <p>{t('addressform.paragraph_forminfo')}</p>
            <Input
              placeholder={t('addressform.input_address1')}
              className='textfield'
              type='text'
              name='address1'
              value={this.state.addressObj.route}
              warning={
                this.state.showStreetWarning &&
                t('addressform.input_address1_warning')
              }
              validationRules={[
                {
                  rule: 'required',
                  err: t('addressform.inputerror_address1_required')
                }
              ]}
            />
            <Input
              placeholder={t('addressform.input_address2')}
              value={this.state.addressObj.route2}
              className='textfield'
              type='text'
              name='address2'
            />
            <Input
              placeholder={t('addressform.input_city')}
              value={this.state.addressObj.city}
              className='textfield'
              type='text'
              name='city'
              validationRules={[
                {
                  rule: 'required',
                  err: t('addressform.inputerror_city_required')
                }
              ]}
            />
            <Dropdown
              label={t('addressform.label_country')}
              name='country'
              required
              provideSelected={this.retrieveCountry}
              selectedOption={this.state.chosenCountry}
              errorValidation={t('addressform.dropdownlabel_country_required')}
              options={this.state.countryList}
              optionkey='name'
            />
            <Dropdown
              label={t('addressform.label_state')}
              name='state'
              required
              selectedOption={this.state.chosenState}
              provideSelected={this.retrieveState}
              errorValidation={t('addressform.dropdownlabel_state_required')}
              options={this.state.stateList}
              optionkey='name'
            />
            <Input
              placeholder={t('addressform.input_postal')}
              className='textfield'
              type='text'
              value={this.state.addressObj.postal_code}
              name='postal'
              validationRules={[
                {
                  rule: 'required',
                  err: t('addressform.inputerror_postal_required')
                }
              ]}
            />
          </div>

          <Navigation
            data={this.state.data}
            isValidated={this.state.isValidated}
            apicall={this.pageApiCall}
          />
        </FormValidation>

        <ErrorMessage
          err={this.state.errorMessage}
          display={this.state.displayError}
          errFromValidation={this.state.errFromValidation}
        />
      </Fragment>
    );
  }
}

export default AddressForm;
