import './mapstyle.css';

export const getMapKeys = {
  v: 'i6Gd59LVlGuMBM-C8WKt2AJoyj4=-DuUdNbW0Qg',
  client: 'gme-coulombtechnologies1',
  language: 'en'
};

export const getGeocodingKeys = {
  key: 'AIzaSyAifDZoA8FDulx7OD_SemQjtBLaZIKX1Fw'
};

let geocoder;

function initGeocoder() {
  const google = window.google;
  geocoder = new google.maps.Geocoder();
}

export function geo(searchBoxValue) {
  if (geocoder === undefined) {
    initGeocoder();
  }
  return new Promise((resolve, reject) => {
    geocoder.geocode(
      {
        address: searchBoxValue
      },
      (res, err) => {
        if (err === 'OK') {
          resolve(res[0]);
        } else {
          reject(err);
        }
      }
    );
  });
}

function toRadians(degrees) {
  return (degrees * Math.PI) / 180;
}

export function distanceBetweenCoordinates(lat1, lon1, lat2, lon2, unit) {
  let radius = 3958.8 * 5280; // in feet

  if (unit !== 'FEET') {
    radius = 6367 * 1000; // in meters
  }

  const diffLat = toRadians(lat2 - lat1);
  const diffLon = toRadians(lon2 - lon1);

  lat1 = toRadians(lat1);
  lat2 = toRadians(lat2);

  const a =
    Math.sin(diffLat / 2) * Math.sin(diffLat / 2) +
    Math.sin(diffLon / 2) *
      Math.sin(diffLon / 2) *
      Math.cos(lat1) *
      Math.cos(lat2);

  return Math.abs(radius * 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a)));
}
