import React, { Component } from 'react';
import FormValidation from '../../Components/FormValidation';
import Input from '../../Components/Input';
import InputFile from '../../Components/InputFile';
import Navigation from '../../Components/Navigation';
import { createFormData } from './helpers';
import { fetchAPIFile } from '../../Router/helpers';
import './style.css';
import ErrorMessage from '../../Components/ErrorMessage';

class StationPhoto extends Component {
  constructor(props) {
    super(props);
    const uiState = {
      info: '',
      fileValue: {},
      fileImage: '',
      pageSaved: false
    };

    if (props.savedData) {
      Object.keys(uiState).forEach(key => {
        if (props.savedData[key]) {
          uiState[key] = props.savedData[key];
        }
      });
    }

    this.state = {
      showLoader: false,
      isValidated: false,
      data: {},
      ...uiState
    };

    this.pageApiCall = this.pageApiCall.bind(this);
    this.onFormSubmit = this.onFormSubmit.bind(this);
  }

  onFormSubmit(data) {
    this.setState({
      displayError: false,
      isValidated: true,
      fileValue: data.imageFile.fileValue,
      fileImage: data.imageFile.fileImage,
      info: data.info
    });
  }

  pageApiCall() {
    this.setState({
      showLoader: true
    });

    return new Promise((resolve, reject) => {
      const formData = createFormData(this.state);
      fetchAPIFile(
        `/installer/station_photo/${this.props.global.deviceId}`,
        formData
      )
        .then(res => {
          this.setState({
            showLoader: false
          });
          if (!res.error) {
            this.setState(
              prevState => ({
                data: {
                  info: prevState.info,
                  fileValue: prevState.fileValue,
                  fileImage: prevState.fileImage,
                  isPreSaved: prevState.isPreSaved
                }
              }),
              () => {
                resolve();
              }
            );
          } else {
            throw Error(res.message);
          }
        })
        .catch(err => {
          this.setState(
            {
              showLoader: false,
              displayError: true,
              errorMessage: err.message,
              isValidated: false,
              errFromValidation: false
            },
            () => {
              reject();
            }
          );
        });
    });
  }

  render() {
    const { t } = this.props;
    return (
      <div className='station-photo'>
        <FormValidation onSubmit={this.onFormSubmit}>
          <Input
            placeholder={t('stationphoto.input_infodriver')}
            info={t('stationphoto.input_infodriver_txt')}
            type='text'
            name='info'
            value={this.state.info}
          />
          <h3>{`${t('stationphoto.title_photo')}*`}</h3>
          <p>{t('stationphoto.paragraph_photo')}</p>

          <InputFile
            required
            name='imageFile'
            fileValue={this.state.fileValue}
            fileImage={this.state.fileImage}
            errorValidation={t('stationphoto.inputfile_photo_required')}
            labelDefault={t('stationphoto.inputfile_takephoto')}
            labelonUse={t('stationphoto.inputfile_retakephoto')}
            placeholderImageFile='/images/ic_take_photos.png'
          />
          {this.state.showLoader && (
          <div className='loader-container'>
            <img src='/images/spinner.svg' alt='loading' />
          </div>
          )}
          <Navigation
            apicall={this.pageApiCall}
            data={this.state.data}
            isValidated={this.state.isValidated}
          />
        </FormValidation>
        <ErrorMessage
          err={this.state.errorMessage}
          display={this.state.displayError}
          errFromValidation={this.state.errFromValidation}
        />
      </div>
    );
  }
}

export default StationPhoto;
