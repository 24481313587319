import React, { Component, Fragment } from 'react';
import './style.css';
import { Translation } from 'react-i18next';
import DataFlowContext from '../Wizard/DataFlowContext';

class Navigation extends Component {
  constructor(props) {
    super(props);
    this.state = {
      lockButton: false
    };
    this.handleNext = this.handleNext.bind(this);
    this.handleBack = this.handleBack.bind(this);
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.isValidated &&
      (!prevProps.isValidated || prevProps.isValidated === undefined)
    ) {
      this.handleNext();
    }
  }

  handleBack() {
    this.context.navigate('back');
  }

  handleNext() {
    if (this.props.isValidated || this.props.isValidated === undefined) {
      // submit
      if (this.props.apicall) {
        // lock button
        this.setState({ lockButton: true });
        // Make api call
        this.props
          .apicall()
          .then(() => {
            this.context.saveData(this.props.data);
          })
          .catch(() => {
            this.setState({
              lockButton: false
            });
          });
      } else {
        this.context.saveData(this.props.data);
      }
    }
  }

  render() {
    return (
      <Translation>
        {t => (
          <Fragment>
            <nav>
              {!this.props.disablePrevButton && (
                <button
                  type='button'
                  className='backButton fullNav'
                  value='back'
                  onClick={this.handleBack}
                >
                  {t('navigation.button_back')}
                </button>
              )}

              <button
                type='submit'
                value='next'
                onClick={this.handleNext}
                disabled={this.state.lockButton}
              >
                {this.props.overrideNextText || t('navigation.button_next')}
              </button>
            </nav>
          </Fragment>
        )}
      </Translation>
    );
  }
}

Navigation.contextType = DataFlowContext;
export default Navigation;
