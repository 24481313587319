// EE_TODO: add async/await here in case of fetch validation requests?
// EE_TODO: add a debounce on the validation
// EE_TODO: look into performance optim

import React, { Component } from 'react';
import validations from './validations';
import SubmitContext from '../FormValidation/SubmitContext';
import ErrorMessage from '../ErrorMessage';
import './style.css';

class Input extends Component {
  constructor(props) {
    super(props);
    this.state = {
      inputValue: props.value || '',
      errorMessage: '',
      displayError: false,
      onFocus: false
    };
    this.inputHandleChange = this.inputHandleChange.bind(this);
    this.getErrorMessage = this.getErrorMessage.bind(this);
    this.validate = this.validate.bind(this);
    this.reset = this.reset.bind(this);
    this.onFocus = this.onFocus.bind(this);
    this.onBlur = this.onBlur.bind(this);
  }

  static getDerivedStateFromProps(props, state) {
    const newState = state;
    // if props onChange is present: the control comes from the parent, not the child
    if (props.onChange) {
      newState.inputValue = props.value;
    }
    return newState;
  }

  componentDidMount() {
    this.context.provideValidateFunction(this.props.name, this.validate);
    this.context.provideResetFunction(this.props.name, this.reset);
  }

  componentWillUnmount() {
    this.context.unmountFunction(this);
  }

  onFocus() {
    this.setState({
      onFocus: true
    });
  }

  onBlur() {
    this.setState({
      onFocus: false
    });
  }

  getErrorMessage(newInput) {
    if (!this.props.validationRules) {
      return '';
    }
    const vRules = this.props.validationRules;
    let isSuccessValidated;
    for (let i = 0; i < vRules.length; i++) {
      if (typeof vRules[i].rule === 'function') {
        isSuccessValidated = vRules[i].rule(newInput);
      } else {
        isSuccessValidated = validations[vRules[i].rule](newInput);
      }
      if (!isSuccessValidated) {
        return vRules[i].err;
      }
    }
    return '';
  }

  validate() {
    const errorMessage = this.getErrorMessage(this.state.inputValue);
    const displayError = !!errorMessage.length;
    this.setState(prevState => ({
      displayError,
      errorMessage: this.getErrorMessage(prevState.inputValue)
    }));

    const val = {};
    val[this.props.name] = !errorMessage.length;

    this.context.provideValidationResult(val, this.state.inputValue);
  }

  reset() {
    this.setState({ inputValue: '' });
  }

  inputHandleChange(e) {
    const newInputValue = e.target.value;

    // if props onChange is present: the control comes from the parent, not the child
    if (this.props.onChange) {
      this.props.onChange(newInputValue)
      this.validate()
    } else {
      this.setState(
        {
          inputValue: newInputValue
        },
        () => {
          this.validate();
        }
      );
    }
  }

  render() {
    const isRequired = this.props.validationRules && this.props.validationRules.length > 0;
    return (
      <div className='input'>
        <input
          className={this.props.className}
          type={this.props.type}
          name={this.props.name}
          onChange={this.inputHandleChange}
          value={this.state.inputValue}
          required={isRequired}
          disabled={this.props.disabled}
          onFocus={this.onFocus}
          onBlur={this.onBlur}
          data-private={this.props.dataPrivate ? this.props.dataPrivate : null}
        />
        {this.props.info && (
          <span className='input-info'>{this.props.info}</span>
        )}
        {this.props.warning && (
          <span className='input-warning'>{this.props.warning}</span>
        )}
        <span
          className={
            this.state.onFocus || this.state.inputValue.length > 0
              ? 'onFocus floating-label'
              : 'floating-label'
          }
        >
          {this.props.placeholder + (isRequired ? '*' : '')}
        </span>
        {this.context.pressedSubmit ? (
          <ErrorMessage
            display={this.state.displayError}
            err={this.state.errorMessage}
            errFromValidation
          />
        ) : null}
      </div>
    );
  }
}

Input.contextType = SubmitContext;
export default Input;
