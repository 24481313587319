import React, { Component } from 'react';
import { BrowserRouter, Route, Switch, Redirect } from 'react-router-dom';

import PrivateRoute from './PrivateRoute';
import Auth from './Auth';
// Components
import Header from '../Components/Header';

// Pages
import Login from '../Pages/Login';
import MacActivation from '../Pages/MacActivation';
import InstallationType from '../Pages/InstallationType';
import MakeModel from '../Pages/MakeModel';
import AlbSmartMeter from '../Pages/AlbSmartMeter';
import Success from '../Pages/Success';
import ForgotPassword from '../Pages/ForgotPassword';
import MacLabel from '../Pages/MacLabel';
import SuccessSwap from '../Pages/SuccessSwap';
import StationPin from '../Pages/StationPin';
import Pinpoint from '../Pages/Pinpoint';
import AddressSearch from '../Pages/AddressSearch';
import StationDetails from '../Pages/StationDetails';
import StationPhoto from '../Pages/StationPhoto';
import TerminalBlock from '../Pages/TerminalBlock';
import AddressForm from '../Pages/AddressForm';
import LocationReq from '../Pages/LocationReq';
import PanelAmpsPhase from '../Pages/PanelAmpsPhase'
import PhaseConnection from '../Pages/PhaseConnection';
import BetaLogin from '../Pages/BetaLogin'

class Router extends Component {
  constructor(props) {
    super(props);
    this.state = { macPrefillFromURL: {} };
  }

  render() {
    if (!this.props.initialized) {
      return null;
    }

    return (
      <BrowserRouter>
        <Header />
        <div className='main'>
          <Switch>
            <PrivateRoute
              exact
              path='/'
              component={MacActivation}
              macPrefillFromURL={this.state.macPrefillFromURL}
            />
            <Route
              path='/login'
              render={props => {
                if (Auth.autoLogin) return <Redirect to='/' />;
                return <Login {...props} macPrefillFromURL={this.state.macPrefillFromURL} />;
              }}
            />
            <Route path='/forgotpassword' component={ForgotPassword} />
            <PrivateRoute path='/installtype' component={InstallationType} />
            <PrivateRoute path='/makemodel' component={MakeModel} />
            <PrivateRoute path='/albsmartmeter' component={AlbSmartMeter} />
            <PrivateRoute path='/success' component={Success} />
            <PrivateRoute path='/successswap' component={SuccessSwap} />
            <PrivateRoute path='/maclabel' component={MacLabel} />
            <PrivateRoute path='/stationpin' component={StationPin} />
            <PrivateRoute path='/address' component={AddressSearch} />
            <PrivateRoute path='/pinpoint' component={Pinpoint} />
            <PrivateRoute path='/stationdetails' component={StationDetails} />
            <PrivateRoute path='/stationphoto' component={StationPhoto} />
            <PrivateRoute path='/terminalblock' component={TerminalBlock} />
            <PrivateRoute path='/addressform' component={AddressForm} />
            <PrivateRoute path='/locationreq' component={LocationReq} />
            <PrivateRoute path='/panelampsphase' component={PanelAmpsPhase} />
            <PrivateRoute path='/phaseconnection' component={PhaseConnection} />
            <Route
              path='/i/:defaultmac/:defaultmacpass'
              render={({ match }) => {
                this.setState({ macPrefillFromURL: match.params });
                return <Redirect to='/' />;
              }}
            />
            <Route
              path='/b/:defaultmac?/:defaultmacpass?'
              render={props => 
                <BetaLogin {...props} />
              }
            />
            <Route
              path='/logout'
              render={() => {
                Auth.signout();
                this.setState({ macPrefillFromURL: '' });
                return <Redirect to='/' />;
              }}
            />
            <Redirect to='/' />
          </Switch>
        </div>
      </BrowserRouter>
    );
  }
}

export default Router;
