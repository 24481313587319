/* eslint-disable react/jsx-one-expression-per-line */
import React, {Component, Fragment} from 'react';
import Navigation from '../../Components/Navigation';
import ErrorMessage from '../../Components/ErrorMessage';
import {fetchAPI, fetchGET} from '../../Router/helpers';
import Radio from '../../Components/Radio';

class AlbSmartMeter extends Component {
  constructor(props) {
    super(props);
    this.state = {
      displayError: false,
      errorMessage: '',
      isAlbEnabled: 0,
      albMeterModel: null,
      data: {}
    };

    this.onAlbEnabledChange = this.onAlbEnabledChange.bind(this);
    this.onSmartMeterChange = this.onSmartMeterChange.bind(this);
    this.pageApiCall = this.pageApiCall.bind(this);
  }

  async componentDidMount() {
    const loadBalanceInfo = await fetchGET(
      `/installer/load_balance_info/${this.props.global.deviceId}`
    );

    this.setState(prevState => ({
      ...prevState,
      isAlbEnabled: loadBalanceInfo.is_alb_enabled,
      albMeterModel: loadBalanceInfo.alb_meter_model
    }));
  }

  onAlbEnabledChange(value) {
    this.setState(prevState => ({
      ...prevState,
      isAlbEnabled: value,
      albMeterModel: value === 0
        ? null
        : (prevState.albMeterModel || 'Socomec')
    }));
  }

  onSmartMeterChange(value) {
    this.setState(prevState => ({
      ...prevState,
      albMeterModel: value
    }));
  }

  pageApiCall() {
    return new Promise((resolve, reject) => {
      fetchAPI(`/installer/load_balance_info/${this.props.global.deviceId}`, {
        is_alb_enabled: this.state.isAlbEnabled,
        alb_meter_model: this.state.albMeterModel,
      })
        .then(res => {
          if (!res.error) {
            resolve();
          } else {
            throw Error(res.message);
          }
        })
        .catch(err => {
          this.setState(
            prevState => ({
              ...prevState,
              displayError: true,
              errorMessage: err.message
            }),
            () => {
              reject();
            }
          );
        });
    });
  }

  render() {
    const {t} = this.props;
    return (
      <Fragment>
        <p><strong>{t('albsmartmeter.active_load_balancing')}</strong></p>
        <span>{t('albsmartmeter.is_station_connected')}</span>
        <Radio
          name={'is_enable'}
          onSelected={this.onAlbEnabledChange}
          ref={this.albEnabledRef}
          selectedOption={this.state.isAlbEnabled}
          labels={[
            {
              text: t('yes'),
              key: 1
            },
            {
              text: t('no'),
              key: 0
            }
          ]}
        />
        <br/>
        {this.state.isAlbEnabled === 1 && (<>
          <span>{t('albsmartmeter.select_smart_meter')}</span>
          <Radio
            name={'meter_model'}
            onSelected={this.onSmartMeterChange}
            selectedOption={this.state.albMeterModel}
            required
            errorValidation={t('albsmartmeter.error_smart_meter_required')}
            labels={[
              {
                text: 'Socomec',
                key: 'Socomec'
              },
              {
                text: 'Siemens',
                key: 'Siemens'
              },
              {
                text: t('albsmartmeter.other_smart_meter'),
                key: 'Other'
              }
            ]}
          />
        </>)}

        <Navigation
          data={this.state.data}
          apicall={this.pageApiCall}
        />
        <ErrorMessage
          display={this.state.displayError}
          err={this.state.errorMessage}
        />
      </Fragment>
    );
  }
}

export default AlbSmartMeter;
