import React, { Component, createRef } from 'react';
import './style.css';
import { Translation } from 'react-i18next';
import SubmitContext from '../FormValidation/SubmitContext';
import ErrorMessage from '../ErrorMessage';

class Dropdown extends Component {
  constructor(props) {
    super(props);
    this.state = {
      options: props.options || [],
      selectedOption: props.selectedOption || 'initial',
      errorMessage: props.errorValidation || '',
      displayError: false
    };
    this.handleChange = this.handleChange.bind(this);
    this.validate = this.validate.bind(this);
    this.reset = this.reset.bind(this);
    this.dropdownRef = createRef();
  }

  static getDerivedStateFromProps(props, state) {
    const newState = state;
    if (props.selectedOption) {
      newState.selectedOption = props.selectedOption;
    }
    newState.options = props.options;
    return newState;
  }

  componentDidMount() {
    this.context.provideValidateFunction(this.props.name, this.validate);
    this.context.provideResetFunction(this.props.name, this.reset);
  }

  handleChange(evt) {
    if (this.props.provideSelected) {
      if (this.props.optionkey) {
        const selectedKey = this.dropdownRef.current.options.selectedIndex - 1;
        if (this.state.options[selectedKey]) {
          this.props.provideSelected(this.state.options[selectedKey]);
        }
      } else if (evt.target.value) {
        this.props.provideSelected(evt.target.value);
      }
    }

    // if props selectedoption is present: the control comes from the parent, not the child
    if (!this.props.selectedOption) {
      this.setState(
        {
          selectedOption: evt.target.value
        },
        () => {
          this.validate();
        }
      );
    } else {
      this.validate();
    }
  }

  validate() {
    let displayError;
    if (this.props.required) {
      displayError = this.state.selectedOption === 'initial';
      this.setState({ displayError });
    }
    const obj = {};
    obj[this.props.name] = !displayError;

    if (this.props.optionkey) {
      const selectedKey = this.dropdownRef.current.options.selectedIndex - 1;
      this.context.provideValidationResult(
        obj,
        this.state.options[selectedKey]
      );
    } else {
      this.context.provideValidationResult(obj, this.state.selectedOption);
    }
  }

  reset() {
    this.setState({
      selectedOption: 'initial'
    });
  }

  componentWillUnmount() {
    this.context.unmountFunction(this);
  }

  render() {
    return (
      <Translation>
        {t => (
          <div className='input input-select'>
            <label>
              <p>
                {this.props.label}
                {this.props.required && !this.props.hideRequiredMark ? '*' : ''}
              </p>
            </label>
            <div className='holder-select'>
              <select
                value={this.state.selectedOption}
                onChange={this.handleChange}
                disabled={
                  this.state.options.length === 0 || this.props.disableDropdown
                }
                name={this.props.name}
                ref={this.dropdownRef}
              >
                <option key='' value='' hidden>
                  { this.props.defaultOption ? this.props.defaultOption : t('component.dropdownlabel_select')}
                </option>
                {this.state.options.map((val, i) => {
                  if (this.props.optionkey) {
                    return (
                      <option
                        key={val[this.props.optionkey] + i}
                        value={val[this.props.optionkey]}
                      >
                        {val[this.props.optionkey]}
                      </option>
                    );
                  }
                  return (
                    <option key={val} value={val}>
                      {val}
                    </option>
                  );
                })}
              </select>
            </div>
            {this.context.pressedSubmit ? (
              <ErrorMessage
                display={this.state.displayError}
                err={this.state.errorMessage}
                errFromValidation
              />
            ) : null}
          </div>
        )}
      </Translation>
    );
  }
}

Dropdown.contextType = SubmitContext;
export default Dropdown;
