export const defaultNavPath = ['/', '/locationreq', '/installtype'];

export function setNavPath(
  currentNav,
  globalData,
  incomingData,
  alreadySavedData
) {
  const navPath = currentNav;
  if (globalData.networkHardwareSwap && incomingData.validMac) {
    return ['/', '/locationreq', '/maclabel', '/successswap'];
  }

  // 3 Phase Station New
  if (
    incomingData.installtype === 'new' &&
    !globalData.isOCPP &&
    globalData.isThreePhase
  ) {
    return [
      '/',
      '/installtype',
      '/address',
      '/addressform',
      '/pinpoint',
      '/stationdetails',
      '/stationphoto',
      '/terminalblock',
      '/stationpin',
      '/success'
    ];
  }

  // Three Phase Station Replace
  if (
    incomingData.installtype === 'replace' &&
    !globalData.isOCPP &&
    globalData.isThreePhase
  ) {
    return [
      '/',
      '/installtype',
      '/maclabel',
      '/terminalblock',
      '/stationpin',
      '/successswap'
    ];
  }

  // Regular Chargepoint Station New
  if (incomingData.installtype === 'new' && !globalData.isOCPP) {
    return [
      '/',
      '/locationreq',
      '/installtype',
      '/address',
      '/addressform',
      '/pinpoint',
      '/stationdetails',
      '/stationphoto',
      '/stationpin',
      '/success'
    ];
  }

  // Regular Chargepoint Station Replace
  if (incomingData.installtype === 'replace' && !globalData.isOCPP) {
    return [
      '/',
      '/locationreq',
      '/installtype',
      '/maclabel',
      '/stationpin',
      '/successswap'
    ];
  }

  // OCPP station New
  if (incomingData.installtype === 'new' && globalData.isOCPP) {
    return [
      '/',
      '/locationreq',
      '/installtype',
      '/makemodel',
      '/address',
      '/addressform',
      '/pinpoint',
      '/stationdetails',
      '/stationphoto',
      '/success'
    ];
  }

  // OCPP station Replace
  if (incomingData.installtype === 'replace' && globalData.isOCPP) {
    return [
      '/',
      '/locationreq',
      '/installtype',
      '/makemodel',
      '/maclabel',
      '/successswap',
      '/success'
    ];
  }

  if (globalData.isOCPP) {
    let ocppNavPath = [...navPath]
    if (globalData.inputPhaseCapability && globalData.inputPhaseCapability !== 'DC') {
      handleOCPPACStations(ocppNavPath, incomingData, globalData)
    } else {
      handleOCPPDCStations(ocppNavPath)
    }

    handleAlbSmartMeter(ocppNavPath, globalData);

    return ocppNavPath
  }

  // by passing through QR Code URL
  if (alreadySavedData['/installtype']) {
    if (
      alreadySavedData['/installtype'].installtype === 'new' &&
      !globalData.isOCPP &&
      incomingData.validMac
    ) {
      return [
        '/',
        '/locationreq',
        '/address',
        '/addressform',
        '/pinpoint',
        '/stationdetails',
        '/stationphoto',
        '/stationpin',
        '/success'
      ];
    }

    if (
      alreadySavedData['/installtype'].installtype === 'replace' &&
      !globalData.isOCPP &&
      incomingData.validMac
    ) {
      return ['/', '/locationreq', '/maclabel', '/stationpin', '/successswap'];
    }
  }

  return navPath;
}

function handleOCPPACStations(navPath, incomingData, globalData) {
  addPanelAmpsPhase(navPath)
  if (incomingData.panelPhase === 3) {
    handle3PhasePanel(navPath, globalData)
  } else if (incomingData.panelPhase === 1) {
    handle1PhasePanel(navPath)
  }
}

function handle3PhasePanel(navPath, globalData) {
  // Need to collect phase connection info for OCPP stations if the panel is 3 phase
  // So, inserting /phaseconnection path after /panelampsphase in the exsiting nav path
  // if it is not already inserted.
  addPhaseConnection(navPath)

  if (globalData.inputPhaseCapability === 'AC_3_PHASE') {
    handleOCPP3PhaseStations(navPath)
  } else {
    handleOCPP1PhaseStations(navPath)
  }
}

function handle1PhasePanel(navPath) {
  // In case the installer selects 3 phase panel in /panelampsphase and then goes back
  // to select 1 phase panel, then we need to remove /phaseconnection from nav path
  // Similarly, remove /terminalblock as well  since for 1 phase breaker panel we need
  // not collect terminal block photo.
  removePhaseConnection(navPath)
  removeTerminalBlock(navPath)
}

function handleOCPP3PhaseStations(navPath) {
  // Need to collect terminal block photo for 3 Phase OCPP stations if the panel is also
  // 3 phase. So inserting /terminalblock path after /pathconnection in the existing nav
  // path if it is not already inserted.
  addTerminalBlock(navPath)
}

function handleOCPP1PhaseStations(navPath) {
  // Need to remove /terminalblock if it is a 1 phase station. This scenario occurs when the installer
  // chooses a 3 phase station in /makemodel and 3 phase breaker panel in /panelampsphase and then
  // goes back to choose a 1 phase station in /makemodel and 3 phase breaker panel in /panelampsphase.
  removeTerminalBlock(navPath)
}

function handleOCPPDCStations(navPath) {
  // If the installer selects an AC station in /makemodel and then goes back to select a DC station,
  // we need to remove all references to /panelampsphase, /phaseconnection and /terminalblock from
  // navigation path.
  removePanelAmpsPhase(navPath)
  removePhaseConnection(navPath)
  removeTerminalBlock(navPath)
}

function addPanelAmpsPhase(navPath) {
  let makeModelNavIndex = navPath.findIndex(element => {
    return element === '/makemodel'
  })
  let panelAmpsPhaseNavIndex = navPath.findIndex(element => {
    return element === '/panelampsphase'
  })
  if (makeModelNavIndex >= 0 && panelAmpsPhaseNavIndex < 0) {
    navPath.splice(makeModelNavIndex + 1, 0, '/panelampsphase')
  }
}

function addPhaseConnection(navPath) {
  let panelAmpsPhaseNavIndex = navPath.findIndex(element => {
    return element === '/panelampsphase'
  })
  let phaseConnectionNavIndex = navPath.findIndex(element => {
    return element === '/phaseconnection'
  })
  if (panelAmpsPhaseNavIndex >= 0 && phaseConnectionNavIndex < 0) {
    navPath.splice(panelAmpsPhaseNavIndex + 1, 0, '/phaseconnection')
  }
}

function addTerminalBlock(navPath) {
  let phaseConnectionNavIndex = navPath.findIndex(element => {
    return element === '/phaseconnection'
  })
  let terminalBlockIndex = navPath.findIndex(element => {
    return element === '/terminalblock'
  })
  if (phaseConnectionNavIndex >= 0 && terminalBlockIndex < 0) {
    navPath.splice(phaseConnectionNavIndex + 1, 0, '/terminalblock')
  }
}

function removePanelAmpsPhase(navPath) {
  let panelAmpsPhaseNavIndex = navPath.findIndex(element => {
    return element === '/panelampsphase'
  })
  if (panelAmpsPhaseNavIndex >= 0) {
    navPath.splice(panelAmpsPhaseNavIndex, 1)
  }
}

function removePhaseConnection(navPath) {
  let phaseConnectionNavIndex = navPath.findIndex(element => {
    return element === '/phaseconnection'
  })
  if (phaseConnectionNavIndex >= 0) {
    navPath.splice(phaseConnectionNavIndex, 1)
  }
}

function removeTerminalBlock(navPath) {
  let terminalBlockIndex = navPath.findIndex(element => {
    return element === '/terminalblock'
  });
  if (terminalBlockIndex >= 0) {
    navPath.splice(terminalBlockIndex, 1);
  }
}

function handleAlbSmartMeter(navPath, globalData) {
  let makeModelNavIndex = navPath.findIndex(element => {
    return element === '/makemodel'
  });
  let albSmartMeterNavIndex = navPath.findIndex(element => {
    return element === '/albsmartmeter'
  });

  if (globalData.isAlbSupported) {
    if (makeModelNavIndex >= 0 && albSmartMeterNavIndex < 0) {
      navPath.splice(makeModelNavIndex + 1, 0, '/albsmartmeter');
    }
  } else {
    if (albSmartMeterNavIndex >= 0) {
      navPath.splice(albSmartMeterNavIndex, 1);
    }
  }
}
