import { fetchGET } from '../../Router/helpers'

export function getPanelAmpsPhase(deviceId) {
    return fetchGET(`/installer/panel_amps_phase/${deviceId}`)
}

export function formatAmpsListForDisplay(panelAmpsListResponse) {
    var panelAmpsList = []
    panelAmpsListResponse.forEach(amps => {
        panelAmpsList.push('' + amps + 'A')
    });
    return panelAmpsList
}

export function formatAmps(panelAmps) {
    return parseInt(panelAmps.slice(0, -1))
}

export function formatPhasesListForDisplay(panelPhasesListResponse, t) {
    var panelPhasesList = []
    panelPhasesListResponse.forEach(phase => {
        const phaseText = phase + ' ' + t('panelampsphase.phase')
        var phaseOption = {
            text: phaseText,
            key: phase
        }
        panelPhasesList.push(phaseOption)
    })
    return panelPhasesList
}

export function formatPhaseFromIndex(index, phasesList) {
    return parseInt(phasesList[index].text.slice(0, 1))
}