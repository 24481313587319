import React, { Component, Fragment } from 'react';
import FormValidation from '../../Components/FormValidation';
import Input from '../../Components/Input';
import Navigation from '../../Components/Navigation';
import Dropdown from '../../Components/Dropdown';
import ErrorMessage from '../../Components/ErrorMessage';
import { fetchAPI, fetchGET } from '../../Router/helpers';
import { getADAList } from './helpers';
import { setPortADA } from '../../Components/Wizard/helpers';
import './style.css';

class StationDetails extends Component {
  constructor(props) {
    super(props);
    const uiState = {
      pLot: '',
      pBuilding: '',
      pLabel: '',
      parkingDual: null,
      adaResult: {},
      selectedOption: {},
      pageSaved: false,
      noParking: false
    };

    if (props.savedData) {
      Object.keys(uiState).forEach(key => {
        if (props.savedData[key]) {
          uiState[key] = props.savedData[key];
        }
      });
    }

    const adaList = getADAList(props.t);

    // Setting presaved result
    if (Object.keys(uiState.adaResult).length > 0) {
      Object.keys(uiState.adaResult).forEach(res => {
        adaList.forEach(list => {
          if (uiState.adaResult[res] === list.key) {
            uiState.selectedOption[res] = list.value;
          }
        });
      });
    }

    this.state = {
      data: {},
      isValidated: false,
      adaList,
      ...uiState
    };
    this.onFormSubmit = this.onFormSubmit.bind(this);
    this.pageApiCall = this.pageApiCall.bind(this);
    this.retrieveFromDropdown = this.retrieveFromDropdown.bind(this);
  }

  componentDidMount() {
    // get port selection for ocpp
    const { isOCPP, deviceId } = this.props.global;
    if (isOCPP) {
      fetchGET(`/installer/parking_accessibility_data/${deviceId}`).then(
        res => {
          this.setState({ ...setPortADA(res.parking_ada) });
        }
      );
    }
  }

  retrieveFromDropdown(select, dropdown) {
    const { adaResult, selectedOption } = this.state;
    adaResult[dropdown] = select.key;
    selectedOption[dropdown] = select.value;
    this.setState({
      adaResult,
      selectedOption
    });
  }

  onFormSubmit(data) {
    this.setState({
      isValidated: true,
      pLot: data.parking,
      pBuilding: data.building,
      pLabel: data.floor
    });
  }

  pageApiCall() {
    return new Promise((resolve, reject) => {
      const { pLot, pBuilding, pLabel, adaResult } = this.state;

      fetchAPI(`/installer/station_details/${this.props.global.deviceId}`, {
        parking_lot: pLot,
        parking_building: pBuilding,
        parking_label: pLabel,
        parking_ada: adaResult
      })
        .then(res => {
          if (!res.error) {
            this.setState(
              prevState => ({
                data: {
                  pLot: prevState.pLot,
                  pBuilding: prevState.pBuilding,
                  pLabel: prevState.pLabel,
                  adaResult: prevState.adaResult,
                  pageSaved: true,
                  parkingDual: prevState.parkingDual,
                  selectedOption: prevState.selectedOption,
                  noParking: prevState.noParking
                }
              }),
              () => {
                resolve();
              }
            );
          } else {
            throw Error(res.message);
          }
        })
        .catch(err => {
          this.setState(
            {
              displayError: true,
              errorMessage: err.message,
              isValidated: false,
              errFromValidation: false
            },
            () => {
              reject();
            }
          );
        });
    });
  }

  render() {
    const { t } = this.props;

    const singleADA = (
      <Fragment>
        <Dropdown
          label={t('stationdetails.dropdownlabel_single')}
          selectedOption={this.state.selectedOption.single}
          optionkey='value'
          name='SingleADA'
          options={this.state.adaList}
          required
          provideSelected={choice => {
            this.retrieveFromDropdown(choice, 'single');
          }}
          errorValidation={t('stationdetails.dropdownlabel_single_required')}
        />
      </Fragment>
    );

    const dualADA = (
      <Fragment>
        <Dropdown
          label={t('stationdetails.dropdownlabel_left')}
          selectedOption={this.state.selectedOption.left}
          optionkey='value'
          name='leftAda'
          options={this.state.adaList}
          required
          provideSelected={choice => {
            this.retrieveFromDropdown(choice, 'left');
          }}
          errorValidation={t('stationdetails.dropdownlabel_left_required')}
        />
        <Dropdown
          label={t('stationdetails.dropdownlabel_right')}
          selectedOption={this.state.selectedOption.right}
          optionkey='value'
          name='rightAda'
          options={this.state.adaList}
          required
          provideSelected={choice => {
            this.retrieveFromDropdown(choice, 'right');
          }}
          errorValidation={t('stationdetails.dropdownlabel_right_required')}
        />
      </Fragment>
    );

    // Setting up ADA
    let ADA = null;
    if (!this.state.noParking) {
      ADA = this.state.parkingDual ? dualADA : singleADA;
    }

    return (
      <Fragment>
        <div className='station-details'>
          <FormValidation onSubmit={this.onFormSubmit}>
            <h3>{t('stationdetails.title_stationLoc')}</h3>
            <div className='garage-image'>
              <img
                src='/images/ic_parking_garage.svg'
                alt='parking garage icon'
              />
            </div>

            <Input
              placeholder={t('stationdetails.input_parkinglot')}
              type='text'
              name='parking'
              info={t('stationdetails.input_parking_info')}
              value={this.state.pLot}
            />

            <Input
              placeholder={t('stationdetails.input_building')}
              type='text'
              info={t('stationdetails.input_building_info')}
              name='building'
              value={this.state.pBuilding}
            />
            <Input
              placeholder={t('stationdetails.input_floor')}
              type='text'
              name='floor'
              info={t('stationdetails.input_floor_info')}
              value={this.state.pLabel}
            />

            <h3 className='withIcon'>
              {t('stationdetails.title_parkingAccess')}
            </h3>
            <img
              src='/images/accessible_icon.svg'
              alt='accessible icon'
              className='accessibleIcon'
            />
            {ADA}

            <ErrorMessage
              err={this.state.errorMessage}
              display={this.state.displayError}
              errFromValidation={this.state.errFromValidation}
            />
            <Navigation
              apicall={this.pageApiCall}
              data={this.state.data}
              isValidated={this.state.isValidated}
            />
          </FormValidation>
        </div>
      </Fragment>
    );
  }
}

export default StationDetails;
