/* eslint-disable react/jsx-one-expression-per-line */
import React, { Component, Fragment } from 'react';
import { Trans } from 'react-i18next';
import Navigation from '../../Components/Navigation';
import Input from '../../Components/Input';
import FormValidation from '../../Components/FormValidation';
import ErrorMessage from '../../Components/ErrorMessage';
import { fetchAPI } from '../../Router/helpers';
import { macFormatting } from '../MacActivation/helpers';

class MacLabel extends Component {
  constructor() {
    super();
    this.state = {
      isValidated: false,
      displayError: false,
      errorMessage: '',
      data: {}
    };
    this.formValidationOutput = this.formValidationOutput.bind(this);
    this.pageApiCall = this.pageApiCall.bind(this);
  }

  formValidationOutput(data) {
    this.setState({ isValidated: true, data });
  }

  pageApiCall() {
    return new Promise((resolve, reject) => {
      fetchAPI(`/installer/swap_station/${this.props.global.deviceId}`, {
        mac_address: this.state.data.mac
      })
        .then(res => {
          if (!res.error) {
            this.setState(
              prevState => ({
                data: {
                  newmac: macFormatting(prevState.data.mac)
                }
              }),
              () => {
                resolve();
              }
            );
          } else {
            throw Error(res.message);
          }
        })
        .catch(err => {
          this.setState(
            {
              displayError: true,
              errorMessage: err.message,
              isValidated: false
            },
            () => {
              reject();
            }
          );
        });
    });
  }

  render() {
    const { t } = this.props;

    return (
      <Fragment>
        {this.props.global.networkHardwareSwap ? (
          <Fragment>
            <p>
              <Trans i18nKey='maclabel.paragraph_direction_networkhardwareswap'>
                You are about to replace the CPHM/ChargePoint Network of an
                existing station. In case you are installing a new station or
                replacing complete installed station, contact{' '}
                <a
                  href='https://www.chargepoint.com/support/'
                  target='_blank'
                  rel='noopener noreferrer'
                >
                  ChargePoint Support
                </a>{' '}
                for assistance.
              </Trans>
            </p>
            <br />
            <p>{t('maclabel.paragraph_direction_networkhardwareswap_enter')}</p>
          </Fragment>
        ) : (
          <p>{t('maclabel.paragraph_direction')}</p>
        )}

        <FormValidation onSubmit={this.formValidationOutput}>
          <Input
            placeholder={t('maclabel.input_macaddress')}
            type='text'
            name='mac'
            validationRules={[
              {
                rule: 'required',
                err: t('maclabel.inputerror_macaddress_required')
              }
            ]}
          />
          <Navigation
            isValidated={this.state.isValidated}
            data={this.state.data}
            apicall={this.pageApiCall}
          />
        </FormValidation>
        <ErrorMessage
          errFromValidation={this.state.errorMessage.indexOf(' ') !== -1}
          display={this.state.displayError}
          err={this.state.errorMessage}
        />
      </Fragment>
    );
  }
}

export default MacLabel;
