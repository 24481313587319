import Cookies from 'universal-cookie';

const Auth = {
  isAuthenticated: false,
  autoLogin: false,
  enableSSOLogin: false,
  errorMessage: null,
  ssoUrl: '',
  name: '',
  autoSignin(name) {
    this.isAuthenticated = true;
    this.autoLogin = true;
    this.name = name;
  },
  signin(username, password) {
    return new Promise((resolve, reject) => {
      fetch('/api/validate_user/', {
        method: 'POST',
        body: JSON.stringify({
          user_name: username,
          user_password: password,
          client_support_sso: false
        }),
        headers: {
          'Content-Type': 'application/json'
        }
      })
        .then(res => {
          if (res.status >= 500) {
            throw res.statusText;
          }
          return res.json();
        })
        .then(res => {
          if (res.error) {
            throw Error(res.message);
          }
          if (res.is_user_valid) {
            this.name = res.full_name;
            this.isAuthenticated = true;
            resolve(res);
          } else {
            throw Error('LOGIN_INCORRECT');
          }
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  signinBeta(betacoulomsess, betaciuisession, betasubdomain) {
    return new Promise((resolve, reject) => {
      fetch('/api/validate_beta_user/', {
        method: 'POST',
        body: JSON.stringify({
          beta_coulomb_sess: betacoulomsess,
          beta_ci_ui_session: betaciuisession,
          beta_subdomain: betasubdomain
        }),
        headers: {
          'Content-Type': 'application/json'
        }
      })
        .then(res => {
          if (res.status >= 500) {
            throw res.statusText;
          }
          return res.json();
        })
        .then(res => {
          if (res.error) {
            throw Error(res.message);
          }
          if (res.is_user_valid) {
            this.name = res.full_name;
            this.isAuthenticated = true;
            resolve(res);
          } else {
            throw Error('LOGIN_INCORRECT');
          }
        })
        .catch(err => {
          reject(err);
        });
    })
  },
  signout(forceRedirect = false) {
    this.isAuthenticated = false;
    this.autoLogin = false;
    const cookies = new Cookies();
    cookies.remove('csrftoken', { path: '/' });

    return fetch('/api/logout')
      .then(() => {
        if (forceRedirect && window.location.pathname !== '/login') {
          window.location.href = '/login';
        }
      });
  },
  isSSOEnabled() {
    return fetch('/sso/settings/').then(res => {
     return res.json();
    }).then(res => {
      this.enableSSOLogin = res.is_sso_enabled;
      this.ssoUrl = res.sso_backend_host;
      return res.is_sso_enabled;
    });
  },
  validateSession() {
    return fetch('/api/validate_user/', {
      credentials: 'include',
    });
  },
  redirectToSSO(macPrefill) {
    if (this.enableSSOLogin) {
      let redirectUrl = window.location.origin;
      if (macPrefill && macPrefill.defaultmac && macPrefill.defaultmacpass) {
        redirectUrl = redirectUrl + '/i/' + macPrefill.defaultmac + '/' + macPrefill.defaultmacpass
      }
      window.location.href = this.ssoUrl + '?redirect=' + encodeURIComponent(redirectUrl);
    }
  }
};

export default Auth;
