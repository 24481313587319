export function macFormatting(mac) {
  // 1st step, remove all the :
  const removedC = mac.replace(/:/g, '');
  const cArray = removedC.split('');

  // 2nd step create array every 4 char
  let tempVal = '';
  const tempArray = [];
  cArray.forEach((x, i) => {
    if (i % 4 === 0 && i > 0 && i <= cArray.length - 4) {
      tempArray.push(tempVal);
      tempVal = x;
    } else {
      tempVal += x;
    }
    if (i === cArray.length - 1) {
      tempArray.push(tempVal);
    }
  });

  // join with :
  const formattedMac = tempArray.join(':');

  // turn  to upper case
  const formattedMacUpper = formattedMac.toUpperCase();

  return formattedMacUpper;
}
