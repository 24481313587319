import React from 'react';
import { withRouter, Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Auth from '../../Router/Auth';
import './style.css';

function Header() {
  const { t } = useTranslation();
  if (Auth.isAuthenticated) {
    return (
      <header id='loggedIn'>
        <div>
          <img
            alt='ChargePoint'
            height='42px'
            width='42px'
            src='/images/ic_cp-logo-round.svg'
          />
          <div>
            <p data-private>
              {t('header.paragraph_intro', { name: Auth.name })}
            </p>
            <Link to='/logout'>{t('header.link_logout')}</Link>
          </div>
        </div>
      </header>
    );
  }
  return (
    <header>
      <img
        alt='ChargePoint'
        height='32px'
        width='172px'
        src='/images/cplogo.png'
      />
    </header>
  );
}

export default withRouter(Header);

// withRouter will re render the header component at each route
// EE_TODO: check for router re-render to see how to smoothly transition
