import {fetchGET} from '../../Router/helpers';

let makeList = [];

// have make list saved in memory
export function fetchMake() {
  return new Promise(resolve => {
    if (makeList.length === 0) {
      fetchGET('/installer/ocpp_make/').then(res => {
        makeList = res;
        resolve(res);
      });
    } else {
      resolve(makeList);
    }
  });
}

const modelList = {};

// have model list saved in memory
export function fetchModel(id) {
  return new Promise(resolve => {
    if (!modelList[id]) {
      fetchGET(`/installer/ocpp_model/${id}`).then(res => {
        modelList[id] = res;
        resolve(res);
      });
    } else {
      resolve(modelList[id]);
    }
  });
}

export async function prefill(state, makeOptions) {
  let obj = {};

  // if its presaved and makeOptions have been fetched but not yet recorded
  if (state.modelID && state.makeID && state.makeOptions.length === 0) {
    obj = {
      makeName: state.makeName,
      imageUrl: state.imageUrl,
      modelName: state.modelName,
      modelID: state.modelID,
      modelOptions: state.modelOptions
    };

    // turn off if you trust backend's make_name
    // get the Make name from id
    makeOptions.forEach(x => {
      if (x.id === state.makeID) {
        obj.makeName = x.name;
      }
    });

    if (state.modelID) {
      // get model options
      obj.modelOptions = await fetchModel(state.makeID);

      // turn off if trust backend's model_number
      // get model name from id rather than the name itself
      obj.modelOptions.forEach(x => {
        if (x.model_id === state.modelID) {
          obj.imageUrl = x.image_url;
          obj.modelName = x.model_name;
          obj.modelID = x.model_id;
        }
      });
    }
  }
  return obj;
}

export function fetchManufacturer(id) {
  return fetchGET(`/installer/device_manufacturer/${id}`);
}
