/*
For i18next usage and config:
https://github.com/i18next/react-i18next/blob/master/example/react/src/App.js
http://i18next.github.io/i18next/pages/doc_init.html
https://www.i18next.com/overview/api#resource-handling
https://itnext.io/ultimate-localization-of-react-mobx-app-with-i18next-efab77712149
*/

import i18n from 'i18next';
import XHR from 'i18next-xhr-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';
import chainedBackend from 'i18next-chained-backend';
import LocalStorageBackend from 'i18next-localstorage-backend';

i18n
  // load translation using xhr -> see /public/locales
  // learn more: https://github.com/i18next/i18next-xhr-backend
  .use(XHR)
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  .use(LanguageDetector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .use(chainedBackend)
  .init({
    fallbackLng: 'en-US',
    useSuspense: false,
    load: 'currentOnly',
    backend: {
      backends: [
        LocalStorageBackend, // primary
        XHR // fallback
      ],
      backendOptions: [
        {
          // prefix for stored languages
          prefix: 'installer_lang_',
          // expiration 7 days
          expirationTime: 7 * 24 * 60 * 60 * 1000,
          // language versions
          defaultVersion: 1.1,
          // can be either window.localStorage or window.sessionStorage. Default: window.localStorage
          store: window.localStorage
        },
        {
          loadPath: '/language/{{lng}}/{{ns}}.json' // xhr load path for my own fallback
        }
      ]
    }
  });

export default i18n;
