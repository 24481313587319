import React, { Component } from 'react';
import Navigation from '../../Components/Navigation';
import { fetchGET } from '../../Router/helpers';
import ErrorMessage from '../../Components/ErrorMessage';
import './style.css';

class StationPin extends Component {
  constructor(props) {
    super(props);
    const uiState = {
      pin: ''
    };

    if (props.savedData) {
      Object.keys(uiState).forEach(key => {
        if (props.savedData[key]) {
          uiState[key] = props.savedData[key];
        }
      });
    }

    this.state = { displayError: false, error: '', ...uiState };
  }

  componentDidMount() {
    let url = `/installer/station_pin/${this.props.global.deviceId}`;
    if (this.props.global.installtype === 'replace') url += '?is_swap=1';

    fetchGET(url)
      .then(res => {
        this.setState({ pin: res.pin });
      })
      .catch(err => {
        this.setState({
          displayError: true,
          error: err.message
        });
      });
  }

  render() {
    const { t } = this.props;
    return (
      <div>
        <p>{t('stationpin.paragraph_direction')}</p>
        <div id='pin'>
          <p>{this.state.pin}</p>
        </div>
        <Navigation data={{}} />
        <ErrorMessage
          display={this.state.displayError}
          err={this.state.error}
        />
      </div>
    );
  }
}

export default StationPin;
