import React, {Component, Fragment} from 'react';
import Dropdown from '../../Components/Dropdown';
import Navigation from '../../Components/Navigation';
import FormValidation from '../../Components/FormValidation';
import Input from '../../Components/Input';
import {fetchAPI} from '../../Router/helpers';
import ErrorMessage from '../../Components/ErrorMessage';
import {fetchMake, fetchManufacturer, fetchModel, prefill} from './helpers';

class MakeModel extends Component {
  constructor(props) {
    super(props);
    const uiState = {
      makeName: '',
      modelOptions: [],
      makeOptions: [],
      modelName: '',
      imageUrl: '',
      stationSerial: '',
      makeID: 0,
      modelID: 0
    };

    if (props.savedData) {
      Object.keys(uiState).forEach(key => {
        if (props.savedData[key]) {
          uiState[key] = props.savedData[key];
        }
      });
    }

    this.state = {
      isValidated: false,
      data: {},
      manufacturer: {is_alb_supported: false},
      errorMessage: '',
      displayError: false,
      isAlbSupported: null,
      ...uiState
    };

    this.formValidationOutput = this.formValidationOutput.bind(this);
    this.retrieveMakeSelected = this.retrieveMakeSelected.bind(this);
    this.retrieveModelSelected = this.retrieveModelSelected.bind(this);
    this.pageApiCall = this.pageApiCall.bind(this);
  }

  async componentDidMount() {
    const makeOptions = await fetchMake();
    const recordedData = await prefill(this.state, makeOptions);

    this.setState(() => ({
      makeOptions,
      ...recordedData
    }));

    if (this.state.makeID > 0) {
      const manufacturerData = await fetchManufacturer(this.props.global.deviceId);
      this.setState(() => ({
        manufacturer: manufacturerData.manufacturer,
      }));
    }
  }

  async retrieveMakeSelected(data) {
    // On retrieve, fetch secondary option
    const modelOptions = await fetchModel(data.id);
    this.setState({
      modelOptions,
      makeName: data.name,
      modelName: 'initial',
      imageUrl: '',
      isAlbSupported: data.is_alb_supported
    });
  }

  retrieveModelSelected(data) {
    this.setState({
      imageUrl: data.image_url,
      modelName: data.model_name,
      modelID: data.model_id
    });
  }

  formValidationOutput(data) {
    this.setState({isValidated: true, data, stationSerial: data.serial});
  }

  pageApiCall() {
    return new Promise((resolve, reject) => {
      fetchAPI(`/installer/save_device_info/${this.props.global.deviceId}`, {
        model_id: this.state.modelID,
        serial_number: this.state.stationSerial
      })
        .then(res => {
          console.log(this.state);
          this.setState(
            prevState => ({
              data: {
                makeName: prevState.makeName,
                modelOptions: prevState.modelOptions,
                modelName: prevState.modelName,
                imageUrl: prevState.imageUrl,
                stationSerial: prevState.stationSerial,
                makeOptions: prevState.makeOptions,
                makeID: prevState.makeID,
                modelID: prevState.modelID,
                input_phase_capability: res.input_phase_capability,
                is_alb_supported: prevState.isAlbSupported !== null
                  ? prevState.isAlbSupported
                  : prevState.manufacturer.is_alb_supported,
              }
            }),
            () => {
              resolve();
            }
          );
        })
        .catch(err => {
          this.setState(
            {
              displayError: true,
              errorMessage: err.message,
              isValidated: false
            },
            () => {
              reject();
            }
          );
        });
    });
  }

  render() {
    const {t} = this.props;

    return (
      <Fragment>
        <FormValidation onSubmit={this.formValidationOutput}>
          <Dropdown
            label={t('makemodel.dropdownlabel_make')}
            selectedOption={this.state.makeName}
            name='Make'
            options={this.state.makeOptions}
            optionkey='name'
            provideSelected={this.retrieveMakeSelected}
            required
            errorValidation={t('makemodel.dropdownerror_make_required')}
          />
          <Dropdown
            label={t('makemodel.dropdownlabel_model')}
            selectedOption={this.state.modelName}
            name='Model'
            optionkey='model_name'
            options={this.state.modelOptions}
            provideSelected={this.retrieveModelSelected}
            required
            errorValidation={t('makemodel.dropdownerror_model_required')}
          />
          <Input
            name='serial'
            placeholder={t('makemodel.input_stationserial')}
            type='text'
            validationRules={[
              {
                rule: 'required',
                err: t('makemodel.input_stationserial_required')
              }
            ]}
            value={this.state.stationSerial}
          />
          {!!this.state.imageUrl && (
            <img
              className='imageFile'
              src={this.state.imageUrl}
              alt={this.state.modelName}
            />
          )}

          <Navigation
            isValidated={this.state.isValidated}
            data={this.state.data}
            apicall={this.pageApiCall}
          />
        </FormValidation>
        <ErrorMessage
          err={this.state.errorMessage}
          display={this.state.displayError}
        />
      </Fragment>
    );
  }
}

export default MakeModel;
