import React from 'react';
import './style.css';

function Marker(props) {
  if (props.mainPin) return <div id='main-pin' />;
  if (props.searchIcon)
    return (
      <img src='/images/ic_search_icon.svg' alt='' className='surrounding' />
    );
  if (props.userIcon)
    return <img src='/images/userslocation.svg' alt='' className='central' />;

  return <img src='/images/pin-surround.png' alt='' className='surrounding' />;
}

export default Marker;
