import React, { Component, Fragment } from 'react';
import { Link } from 'react-router-dom';
import { Translation } from 'react-i18next';
import ErrorMessage from '../../Components/ErrorMessage';
import './style.css';
import FormValidation from '../../Components/FormValidation';
import Input from '../../Components/Input';

class ForgotPassword extends Component {
  constructor(props) {
    super(props);
    this.state = {
      displayError: false,
      errorMessage: '',
      displaySuccess: false
    };
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleSubmit(data) {
    fetch('/api/forget_password/', {
      method: 'POST',
      body: JSON.stringify({
        user_name: data.username
      }),
      headers: {
        'Content-Type': 'application/json'
      }
    })
      .then(res => {
        if (res.status >= 500) {
          throw res.statusText;
        }
        return res.json();
      })
      .then(res => {
        if (res.error) {
          throw Error(res.message);
        } else {
          this.setState({
            displaySuccess: true,
            displayError: false,
            errorMessage: ''
          });
        }
      })
      .catch(err => {
        this.setState({
          displayError: true,
          errorMessage: err.message,
          displaySuccess: false
        });
      });
  }

  render() {
    return (
      <Translation>
        {t => (
          <Fragment>
            <h2>{t('header.title_forgot')}</h2>
            <div id='forgetpassword'>
              <FormValidation onSubmit={this.handleSubmit}>
                <p>{t('forgotpassword.paragraph_description')}</p>
                <Input
                  dataPrivate='redact'
                  placeholder={t('forgotpassword.input_username')}
                  className='textfield'
                  type='text'
                  name='username'
                  validationRules={[
                    {
                      rule: 'required',
                      err: t('forgotpassword.inputerror_username_required')
                    }
                  ]}
                />
                <input type='submit' value={t('forgotpassword.input_submit')} />
              </FormValidation>
              {this.state.displaySuccess && (
                <p className='center'>
                  {t('forgotpassword.paragraph_success_reset')}
                </p>
              )}
              <div className='center forgotpassword'>
                <Link to='/login'>{t('forgotpassword.link_login')}</Link>
              </div>
              <ErrorMessage
                display={this.state.displayError}
                err={this.state.errorMessage}
              />
            </div>
          </Fragment>
        )}
      </Translation>
    );
  }
}

export default ForgotPassword;
