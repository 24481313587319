import React, { Component } from 'react';
import { Redirect, Link } from 'react-router-dom';
import { Translation } from 'react-i18next';
import Auth from '../../Router/Auth';
import FormValidation from '../../Components/FormValidation';
import Input from '../../Components/Input';
import ErrorMessage from '../../Components/ErrorMessage';
import './style.css';
import { mixpanelSubmit } from '../../utils/mixpanel';

class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      redirectToReferrer: false,
      displayError: false,
      errorMessage: '',
      lockButton: false,
      macPrefill: props.macPrefillFromURL ? props.macPrefillFromURL : {}
    };
    this.login = this.login.bind(this);
  }

  componentDidMount() {
    if (Auth.enableSSOLogin && Auth.errorMessage) {
      this.setState({
        displayError: true,
        errorMessage: Auth.errorMessage
      });
    }
  }

  login(data) {
    if (Auth.enableSSOLogin) {
      Auth.redirectToSSO(this.state.macPrefill);
      return;
    }
    this.setState({ lockButton: true });
    Auth.signin(data.username, data.password)
      .then(() => {
        mixpanelSubmit('Login', { 'Login Successful': true });
        this.setState({
          redirectToReferrer: true
        });
      })
      .catch(err => {
        this.setState({
          displayError: true,
          errorMessage: err.message,
          lockButton: false
        });
      });
  }

  render() {
    const { from } = this.props.location.state || { from: { pathname: '/' } };
    const { redirectToReferrer } = this.state;

    if (redirectToReferrer) return <Redirect to={from} />;

    return (
      <Translation>
        {t => (
          <div className='sign-up-header'>
            <img
              alt='ChargePoint'
              src='/images/web-signup-header-min.png'
            />
            <h2>{t('header.title_notloggedin')}</h2>
            <FormValidation onSubmit={this.login}>
              {!Auth.enableSSOLogin &&
              <Input
                dataPrivate='redact'
                placeholder={t('login.input_username')}
                className='textfield'
                type='text'
                name='username'
                validationRules={[
                  {
                    rule: 'required',
                    err: t('login.inputerror_username_required')
                  }
                ]}
              />}
              {!Auth.enableSSOLogin &&
              <Input
                placeholder={t('login.input_password')}
                className='textfield'
                type='password'
                name='password'
                validationRules={[
                  {
                    rule: 'required',
                    err: t('login.inputerror_password_required')
                  }
                ]}
              />}
              <input
                type='submit'
                value={t('login.input_submitlogin')}
                disabled={this.state.lockButton}
              />
            </FormValidation>

            <div className='center'>
              <ErrorMessage
                display={this.state.displayError}
                err={this.state.errorMessage}
              />
            </div>

            {!Auth.enableSSOLogin &&
              <div className='center forgotpassword'>
                <Link to='/forgotpassword'>{t('login.link_forgotpass')}</Link>
              </div>
            }

            <div className='center noaccount'>
              <p>{t('login.paragraph_noaccount')}</p>

              <a href='https://www.chargepoint.com/partners/installers'>
                {t('login.link_installer')}
              </a>
            </div>
          </div>
        )}
      </Translation>
    );
  }
}

export default Login;
