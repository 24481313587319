/* eslint-disable react/jsx-one-expression-per-line */
import React, { Fragment, Component } from 'react';
import FormValidation from '../../Components/FormValidation';
import Navigation from '../../Components/Navigation';
import Dropdown from '../../Components/Dropdown';
import ErrorMessage from '../../Components/ErrorMessage';
import './style.css';
import { fetchAPI, fetchGET } from '../../Router/helpers'
import { formatPhaseConnectionsListForDisplay,
         formatPhaseConnection } from './helpers'

class PhaseConnection extends Component {
  constructor(props) {
    super(props)
    const uiState = {
      phaseConnectionsList: [],
      phaseConnection: [''],
      portCount: 1
    }

    if (props.savedData) {
      Object.keys(uiState).forEach(key => {
        if (props.savedData[key]) {
          uiState[key] = props.savedData[key];
        }
      });
    }

    this.state = {
      isValidated: false,
      data: {},
      ...uiState
    };
  }

  componentDidMount() {
    return fetchGET(`/installer/panel_phase_connections/${this.props.global.deviceId}`)
      .then(res => {
        const portCount = res.port_count ? parseInt(res.port_count) : 1;
        const phaseConnectionsList = formatPhaseConnectionsListForDisplay(res.panel_phase_connection.values)
        var phaseConnection = ['']
        if (res.panel_phase_connection.saved && res.panel_phase_connection.saved[0]) {
          for (let i = 0; i < portCount; i++) {
            phaseConnection[i] = formatPhaseConnectionsListForDisplay([res.panel_phase_connection.saved[i]])[0];
          }
        } else {
          for (let i = 0; i < portCount; i++) {
            if (this.state.phaseConnection[i] && phaseConnectionsList.includes(this.state.phaseConnection[i])) {
              phaseConnection[i] = this.state.phaseConnection[i];
            }
          }
        }
        this.setState({
          phaseConnectionsList: phaseConnectionsList,
          phaseConnection: phaseConnection,
          portCount: portCount
        });
      })
      .catch(err => {
        this.setState({
          displayError: true,
          errorMessage: err.message,
          isValidated: false,
          errFromValidation: false
        })
      })    ;
  }

  onPhaseSelectedPortOne = (phaseConnection) => {
    const phaseConnections = this.state.phaseConnection;
    phaseConnections[0] = phaseConnection;
    this.setState({
      phaseConnection: phaseConnections
    })
  }

  onPhaseSelectedPortTwo = (phaseConnection) => {
    const phaseConnections = this.state.phaseConnection;
    phaseConnections[1] = phaseConnection;
    this.setState({
      phaseConnection: phaseConnections
    })
  }

  onFormSubmit = (formData) => {
    this.setState({
      isValidated: true
    })
  }

  pageApiCall = () => {
    return new Promise((resolve, reject) => {
      const phaseConnection = formatPhaseConnection(this.state.phaseConnection)

      fetchAPI(`/installer/panel_phase_connection`, {
        device_id: this.props.global.deviceId,
        panel_phase_connection: phaseConnection
      })
        .then(res => {
          if (!res.error) {
            this.setState(
              prevState => ({
                data: {
                  phaseConnection: prevState.phaseConnection
                }
              }),
              () => {
                resolve()
              }
            );
          } else {
            throw Error(res.message)
          }
        })
        .catch(err => {
          this.setState(
            {
              displayError: true,
              errorMessage: err.message,
              isValidated: false,
              errFromValidation: false
            },
            () => {
              reject();
            }
          );
        });
    });
  }

  renderPhaseSelections = () => {
    let dropdowns = [];
    for (var i = 0; i < this.state.portCount; i++) {
      dropdowns.push();
    }
    return dropdowns;
  }

  render() {
    const { t } = this.props

    return (
      <Fragment>
        <div id='panelconnection-form'>
          <FormValidation onSubmit={this.onFormSubmit}>
            <p className='phaseconnection-label'>{t('phaseconnection.label_phaseconnection')}</p>
            <p className='sticker-reference-hint'>{t('phaseconnection.hint_sticker_reference')}</p>
            <div className='phaseconnection-image'>
              <img
                src={this.props.global.inputPhaseCapability === 'AC_3_PHASE'
                    ? '/images/ic_3p_phase_connection.svg'
                    : '/images/ic_1p_phase_connection.svg'}
                alt={t('phaseconnection.alt_phase_connection_image')}
              />
            </div>
            <p className='panel-phases-question'>{t('phaseconnection.question_panel_phases')}</p>
            <Dropdown
                name='phase_connection_0'
                required
                hideRequiredMark
                provideSelected={this.onPhaseSelectedPortOne}
                selectedOption={this.state.phaseConnection[0]}
                errorValidation={t('phaseconnection.dropdownerror_phaseconnection_required')}
                options={this.state.phaseConnectionsList}
                defaultOption={t('phaseconnection.dropdownlabel_select_phaseconnection')}
            />
            {this.state.portCount > 1 && <Dropdown
                name='phase_connection_1'
                required
                hideRequiredMark
                provideSelected={this.onPhaseSelectedPortTwo}
                selectedOption={this.state.phaseConnection[1]}
                errorValidation={t('phaseconnection.dropdownerror_phaseconnection_required')}
                options={this.state.phaseConnectionsList}
                defaultOption={t('phaseconnection.dropdownlabel_select_phaseconnection')}
            />}
            <p className='selection-note'>{t('phaseconnection.note_selection')}</p>
            <ErrorMessage
              err={this.state.errorMessage}
              display={this.state.displayError}
              errFromValidation={this.state.errFromValidation}
            />            
            <Navigation
              data={this.state.data}
              isValidated={this.state.isValidated}
              apicall={this.pageApiCall}
            />
          </FormValidation>
        </div>
      </Fragment>
    )
  }
}

export default PhaseConnection;