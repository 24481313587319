import mixpanel from 'mixpanel-browser';

const isProdBuild = process.env.NODE_ENV === 'production';
const isProd = window.location.hostname.split('.')[1] === 'chargepoint';
const ProdQAChooser =
  window.location.hostname.split('.')[1] === 'chargepoint' ? '' : 'ev-';

/*
const tokens = {
  'qa.us.mixpanel': '548b7e8a2be5c694d148c634ca8ae972',
  'prod.us.mixpanel': '7006b932a849e5649af9ac208147705c',
  'qa.eu.mixpanel': 'b67854182f212aeff66ccc95379cc372',
  'prod.eu.mixpanel': 'ae964b79e108da198d9bd1de722b3d5a',
  'prod.au.mixpanel': '1dbfc6f3951ed65a1a310aa0b145adba',
  'prod.ca.mixpanel': 'f19f2bd24476384aac7f5e368e11d875'
};
*/

export const mpConfig = {
  'User Type': 'Installer',
  'ChargePoint Product': 'Installer Portal',
  'Analytics Reporting Version': 1
};

export function initializeMixpanel() {
  fetch(
    `https://discovery.${ProdQAChooser}chargepoint.com/discovery/v3/globalconfig`,
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({})
    }
  )
    .then(res => {
      if (res.status !== 200) {
        throw new Error('failed mixpanel');
      }
      return res.json();
    })
    .then(data => {
      // mixpanel.init(tokens[data.analytics.mixpanel.tokenAlias]);
      mixpanel.init('b57d66b37b114ba0455f1f176b2de3fa');
      mixpanel.register(mpConfig);
      Mixpanel.initialized = true;
    }).catch((error) => {
      console.log(error);
      Mixpanel.initialized = false;
    });
}

export const Mixpanel = {
  initialized: false,
  identify: id => {
    if (isProdBuild && isProd) mixpanel.identify(id);
  },
  alias: id => {
    if (isProdBuild && isProd) mixpanel.alias(id);
  },
  track: (name, props) => {
    // if (isProdBuild && isProd) mixpanel.track(name, props);
    if (isProdBuild) mixpanel.track(name, props);
  },
  people: {
    set: props => {
      if (isProdBuild && isProd) mixpanel.people.set(props);
    }
  }
};

function sanitizedObject(obj) {
  const newObj = {};
  Object.keys(obj).forEach(key => {
    if (obj[key]) {
      const splitKey = key.split('_');
      const firstToUC = splitKey.map(
        x => x.charAt(0).toUpperCase() + x.slice(1)
      );
      const newKey = firstToUC.join(' ');
      newObj[newKey] = obj[key];
    }
  });
  return newObj;
}

function sanitizedEvent(currentPage) {
  let event;
  switch (currentPage) {
    case '/':
      event = 'Mac Validation';
      break;
    case '/makemodel':
      event = 'Make & Model';
      break;
    case '/installtype':
      event = 'Installation Type';
      break;
    case '/pinpoint':
      event = 'Pinpoint';
      break;
    case '/address':
      event = 'Address Search';
      break;
    case '/stationdetails':
      event = 'Station Details';
      break;
    case '/stationphoto':
      event = 'Station Photo';
      break;
    case '/locationreq':
      event = 'Location Requirement';
      break;
    case '/addressform':
      event = 'Address Form';
      break;
    case '/success':
      event = 'Success';
      break;
    case '/successswap':
      event = 'Success Swap';
      break;
    case '/maclabel':
      event = 'Mac Label';
      break;

    default:
      event = currentPage;
  }
  return event;
}

export function mixpanelSubmit(currentPage, dataToSave) {
  if (!Mixpanel.initialized) {
    return; 
  }
  const event = sanitizedEvent(currentPage);
  const obj = sanitizedObject(dataToSave);
  Mixpanel.track(event, obj);
}

// Set flow tracking
let isFlowStarted = false;
let flowName = '';

// Starting Mixpanel Flow
function mpFlowStart(globalData) {
  if (!Mixpanel.initialized) {
    return; 
  }
  if (globalData.isOCPP) flowName = 'OCPP';
  if (!globalData.isOCPP) flowName = globalData.modelName;

  // register the flow name to super property
  mixpanel.register({
    ...mpConfig,
    'User Flow': `${flowName}`
  });

  // Send the Start Flow Event
  mixpanel.track(`User Flow Start - ${flowName}`);
}

// Ending Mixpanel Flow
function mpFlowEnd() {
  if (!Mixpanel.initialized) {
    return; 
  }
  // Send end Event
  mixpanel.track(`User Flow End - ${flowName}`);
  // Remove User Flow from Super Properties
  mixpanel.register(mpConfig);
}

export function setMixpanelFlow(incomingData, globalData) {
  // if the flow hasn't started and passed mac validation
  if (incomingData.isOCPP !== undefined && !isFlowStarted) {
    mpFlowStart(globalData);
    isFlowStarted = true;
  }
  // if passed mac validation but flow has already started
  if (incomingData.isOCPP !== undefined && isFlowStarted) {
    mpFlowEnd();
    mpFlowStart(globalData);
  }
  if (globalData.isLastPage) {
    mpFlowEnd();
    isFlowStarted = false;
  }
}
