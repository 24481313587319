// import { Maps } from 'google-map-react';
// https://maps.googleapis.com/maps/api/js?key=AIzaSyAifDZoA8FDulx7OD_SemQjtBLaZIKX1Fwlibraries=places

export function getMapOptions(maps) {
  return {
    streetViewControl: false,
    scaleControl: true,
    fullscreenControl: true,
    rotateControl: false,
    tilt: 0,
    tiltControl: false,
    // styles: [
    //   {
    //     featureType: 'poi.business',
    //     elementType: 'labels',
    //     stylers: [
    //       {
    //         visibility: 'off'
    //       }
    //     ]
    //   }
    // ],
    // gestureHandling: 'greedy',
    gestureHandling: 'cooperative',

    disableDoubleClickZoom: true,
    // minZoom: 11,
    // maxZoom: 18,

    mapTypeControl: true,
    mapTypeId: maps.MapTypeId.SATELLITE,
    mapTypeControlOptions: {
      style: maps.MapTypeControlStyle.HORIZONTAL_BAR,
      position: maps.ControlPosition.TOP_LEFT,
      mapTypeIds: [maps.MapTypeId.ROADMAP, maps.MapTypeId.SATELLITE]
    },

    zoomControl: true,
    clickableIcons: false
  };
}

/* 
Dev notes:
* https://github.com/google-map-react/google-map-react/issues/585
* https://github.com/google-map-react/google-map-react/blob/master/API.md
* https://www.fullstackreact.com/articles/how-to-write-a-google-maps-react-component/
* https://github.com/google-map-react/google-map-react/issues/32
* https://github.com/google-map-react/google-map-react/issues/43
* https://github.com/fullstackreact/google-maps-react
*/

/* 
Dev notes:
* https://maps.googleapis.com/maps/api/js?key=AIzaSyAifDZoA8FDulx7OD_SemQjtBLaZIKX1Fwlibraries=places
* https://github.com/google-map-react/google-map-react/issues/585
* https://github.com/google-map-react/google-map-react/blob/master/API.md
* https://www.fullstackreact.com/articles/how-to-write-a-google-maps-react-component/
* https://github.com/google-map-react/google-map-react/issues/32
* https://github.com/google-map-react/google-map-react/issues/43
* https://github.com/fullstackreact/google-maps-react
*/
