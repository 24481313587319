const DEFAULT_LOCALE = 'en-US';

const SUPPORTED_LOCALES = new Map([
  ['en-GB', 'en-GB'],
  ['en-AU', 'en-GB'],
  ['en-NZ', 'en-GB'],
  ['fr-CA', 'fr-CA'],

  ['en', 'en-US'],
  ['fr', 'fr-FR'],
  ['es', 'es-ES'],
  ['de', 'de-DE'],
  ['it', 'it-IT'],
  ['nl', 'nl-NL'],
  ['sv', 'sv-SE'],
  ['nb', 'nb-NO'],
  ['da', 'da-DK'],
  ['pt', 'pt-PT']
]);

export function getSupportedLocale(locale) {
  if (typeof locale === 'string') {
    if (SUPPORTED_LOCALES.has(locale)) {
      return SUPPORTED_LOCALES.get(locale);
    }

    // iOS can set only 'de' in webview
    const mainLocale = locale.substring(0, 2).toLowerCase();
    if (SUPPORTED_LOCALES.has(mainLocale)) {
      return SUPPORTED_LOCALES.get(mainLocale);
    }
  }

  return DEFAULT_LOCALE;
}

export function loadGoogleMapsForLanguage(language) {
  let googleMapsSrc = 'https://maps.googleapis.com/maps/api/js?libraries=places&key=AIzaSyAifDZoA8FDulx7OD_SemQjtBLaZIKX1Fw';
  const langArray = language.split('-');
  if (langArray.length === 1) {
    googleMapsSrc = googleMapsSrc + '&language=' + langArray[0];
  } else if (langArray.length === 2) {
    googleMapsSrc = googleMapsSrc + '&language=' + langArray[0] + '&region=' + langArray[1];
  }
  googleMapsSrc = googleMapsSrc + '&callback=Function.prototype';

  const googleMapsScript = document.createElement('script');
  googleMapsScript.src = googleMapsSrc;
  googleMapsScript.async = true;
  googleMapsScript.defer = true;
  document.body.appendChild(googleMapsScript);
}
