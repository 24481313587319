import React, { Component } from 'react';
import './style.css';
import { geo } from '../../utils/map';

class SearchBox extends Component {
  constructor(props) {
    super(props);
    this.state = {
      onFocus: false,
      inputIsFilled: props.defaultValue ? props.defaultValue.length > 0 : false,
      inputValue: props.defaultValue || ''
    };
    this.autocomplete = null;
    this.event = null;
    this.onFocus = this.onFocus.bind(this);
    this.onBlur = this.onBlur.bind(this);
    this.onChange = this.onChange.bind(this);
    this.formSubmit = this.formSubmit.bind(this);
    this.onKeyDown = this.onKeyDown.bind(this);
    this.inputRef = React.createRef();
    this.isPrevESC = false;
  }

  componentDidMount() {
    const {
      types = ['(cities)'],
      componentRestrictions,
      bounds,
      fields = [
        'address_components',
        'geometry.location',
        'place_id',
        'formatted_address'
      ]
    } = this.props;
    const config = {
      types,
      bounds,
      fields
    };

    if (componentRestrictions) {
      config.componentRestrictions = componentRestrictions;
    }

    this.disableAutofill();

    const google = window.google;

    this.autocomplete = new google.maps.places.Autocomplete(
      this.inputRef.current,
      config
    );

    this.event = this.autocomplete.addListener(
      'place_changed',
      this.onSelected.bind(this)
    );
  }

  componentWillUnmount() {
    if (this.event) {
      this.event.remove();
    }
  }

  onFocus() {
    this.setState({ onFocus: true });
  }

  onBlur() {
    this.setState({ onFocus: false });
  }

  onChange(evt) {
    if (this.props.onChange) {
      this.props.onChange(evt);
    }

    const inputValue = evt.target.value;
    this.setState({
      inputIsFilled: evt.target.value.length > 0,
      inputValue
    });
  }

  async onSelected() {
    const { onPlaceSelected } = this.props;
    const { inputValue } = this.state;
    // check first with autocomplete event
    const selectedPlace = this.autocomplete.getPlace() || { name: inputValue };

    // check if there is anything in text
    if (inputValue.length > 0) {
      // if autocomplete event fail, check with geolocation
      if (selectedPlace.name) {
        try {
          const geoRes = await geo(inputValue);
          // geo success
          if (onPlaceSelected) {
            onPlaceSelected(geoRes);
          }

          this.setState({
            inputValue: geoRes.formatted_address
          });
        } catch (err) {
          // geo failure, pass back
          if (onPlaceSelected) {
            onPlaceSelected(selectedPlace);
          }

          this.setState({
            inputValue: selectedPlace.name
          });
        }
      } else {
        // autocomplete success
        if (onPlaceSelected) {
          onPlaceSelected(selectedPlace);
        }

        this.setState({
          inputValue: selectedPlace.formatted_address
        });
      }
    }
  }

  onKeyDown(e) {
    if (this.isPrevESC && e.keyCode === 13) {
      this.onSelected();
      this.isPrevESC = false;
    } else {
      this.isPrevESC = e.keyCode === 27;
    }
  }

  formSubmit(e) {
    // if you press enter without pressing next
    e.preventDefault();
    if (this.state.inputValue.length === 0)
      this.props.onPlaceSelected('GOOGLE_NO_INPUT');
  }

  disableAutofill() {
    // Autofill workaround adapted from https://stackoverflow.com/questions/29931712/chrome-autofill-covers-autocomplete-for-google-maps-api-v3/49161445#49161445
    if (window.MutationObserver) {
      const observerHack = new MutationObserver(() => {
        observerHack.disconnect();
        if (this.inputRef.current) {
          this.inputRef.current.autocomplete = 'disable-autofill';
        }
      });
      observerHack.observe(this.inputRef.current, {
        attributes: true,
        attributeFilter: ['autocomplete']
      });
    }
  }

  render() {
    const { placeholder } = this.props;

    return (
      <form onSubmit={this.formSubmit}>
        <div id='searchbox' className={this.state.onFocus ? 'focus' : ''}>
          <input
            type='text'
            ref={this.inputRef}
            placeholder=''
            value={this.state.inputValue}
            onFocus={this.onFocus}
            onBlur={this.onBlur}
            onChange={this.onChange}
            onKeyDown={this.onKeyDown}
          />
          <span
            id='searcbbox-label'
            className={
              this.state.onFocus || this.state.inputIsFilled ? 'onFocus' : ''
            }
          >
            {`${placeholder}*`}
          </span>
        </div>
        <input type='submit' className='hidden' />
      </form>
    );
  }
}

export default SearchBox;
