/* eslint-disable react/jsx-one-expression-per-line */
import React, {Fragment, Component} from 'react';
import FormValidation from '../../Components/FormValidation';
import Navigation from '../../Components/Navigation';
import Input from '../../Components/Input';
import Dropdown from '../../Components/Dropdown';
import ErrorMessage from '../../Components/ErrorMessage';
import Radio from '../../Components/Radio'
import {fetchAPI} from '../../Router/helpers'
import {
  formatAmpsListForDisplay,
  formatAmps,
  formatPhasesListForDisplay,
  getPanelAmpsPhase
} from './helpers'
import './style.css'

class PanelAmpsPhase extends Component {
  constructor(props) {
    super(props);
    const uiState = {
      panelId: '',
      ampsList: [],
      panelAmps: '',
      phasesList: [],
      panelPhase: ''
    }

    if (props.savedData) {
      Object.keys(uiState).forEach(key => {
        if (props.savedData[key]) {
          uiState[key] = props.savedData[key];
        }
      });
    }

    this.state = {
      isValidated: false,
      data: {},
      ...uiState
    };
  }

  componentDidMount() {
    getPanelAmpsPhase(this.props.global.deviceId)
      .then(res => {
        const {t} = this.props

        const ampsList = formatAmpsListForDisplay(res.panel_amps.values)
        const phasesList = formatPhasesListForDisplay(res.panel_phase.values, t)

        // First check for saved values. If not, check for values in the current state.
        // If not fallback to the default.
        const panelId = res.panel_id.saved ? res.panel_id.saved :
          (this.state.panelId ? this.state.panelId : '')
        const panelAmps = res.panel_amps.saved ? formatAmpsListForDisplay([res.panel_amps.saved])[0] :
          (this.state.panelAmps ? this.state.panelAmps : '')
        const panelPhase = res.panel_phase.saved ? res.panel_phase.saved :
          (this.state.panelPhase ? this.state.panelPhase : res.panel_phase.default)
        this.setState({
          panelId: panelId,
          ampsList: ampsList,
          panelAmps: panelAmps,
          phasesList: phasesList,
          panelPhase: panelPhase
        })
      })
      .catch(err => {
        this.setState(
          {
            displayError: true,
            errorMessage: err.message,
            isValidated: false,
            errFromValidation: false
          }
        )
      })
  }

  onPanelIdChange = (panelId) => {
    this.setState({
      panelId: panelId
    })
  }

  onPanelAmpsSelected = (panelAmps) => {
    this.setState({
      panelAmps: panelAmps
    })
  }

  onPanelPhaseSelected = (phase) => {
    this.setState({
      panelPhase: phase
    });
  }

  onFormSubmit = () => {
    this.setState({
      isValidated: true,
    })
  }

  pageApiCall = () => {
    return new Promise((resolve, reject) => {
      const {panelId, panelPhase} = this.state;
      const panelAmps = formatAmps(this.state.panelAmps)

      fetchAPI(`/installer/panel_amps_phase/${this.props.global.deviceId}`, {
        panel_id: panelId,
        panel_amps: panelAmps,
        panel_phase: panelPhase
      })
        .then(res => {
          if (!res.error) {
            this.setState(
              prevState => ({
                data: {
                  panelId: prevState.panelId,
                  panelAmps: prevState.panelAmps,
                  panelPhase: prevState.panelPhase
                }
              }),
              () => {
                resolve()
              }
            );
          } else {
            throw Error(res.message)
          }
        })
        .catch(err => {
          this.setState(
            {
              displayError: true,
              errorMessage: err.message,
              isValidated: false,
              errFromValidation: false
            },
            () => {
              reject();
            }
          );
        });
    });
  }

  render() {
    const {t} = this.props;

    return (
      <Fragment>
        <div id='panelampsphase-form'>
          <FormValidation onSubmit={this.onFormSubmit}>
            <p className='panel-label'>{t('panelampsphase.label_panel')}</p>
            <Input
              placeholder={t('panelampsphase.input_panel_id')}
              type='text'
              name='panel_id'
              value={this.state.panelId}
              validationRules={[{'rule': 'required', 'err': t('panelampsphase.error_message_panel_id')}]}
              onChange={this.onPanelIdChange}
            />
            <p className='select-amps-label'>{t('panelampsphase.label_select_amps')}</p>
            <Dropdown
              name='amps'
              required
              hideRequiredMark
              provideSelected={this.onPanelAmpsSelected} // TODO: Rename to onSelected
              selectedOption={this.state.panelAmps}
              errorValidation={t('panelampsphase.error_message_amps')}
              options={this.state.ampsList}
              defaultOption={t('panelampsphase.list_select_amps')}
            />
            <p className='phase-label'>{t('panelampsphase.label_phase')}</p>
            <Radio
              onSelected={this.onPanelPhaseSelected}
              selectedOption={this.state.panelPhase}
              labels={this.state.phasesList}
            />
            <ErrorMessage
              err={this.state.errorMessage}
              display={this.state.displayError}
              errFromValidation={this.state.errFromValidation}
            />
            <Navigation
              data={this.state.data}
              isValidated={this.state.isValidated}
              apicall={this.pageApiCall}
            />
          </FormValidation>
        </div>
      </Fragment>
    );
  }
}

export default PanelAmpsPhase;
