import React, { Component, Fragment } from 'react';
import GoogleMapReact from 'google-map-react';
import Navigation from '../../Components/Navigation';
import Marker from '../../Components/Marker';
import ErrorMessage from '../../Components/ErrorMessage';
import FormValidation from '../../Components/FormValidation';

import { fetchGET, fetchAPI } from '../../Router/helpers';
import { getMapKeys, distanceBetweenCoordinates } from '../../utils/map';
import { getMapOptions } from './mapConfig';
import './style.css';

class Pinpoint extends Component {
  constructor(props) {
    super(props);
    const uiState = {
      center: {
        lat: null,
        lng: null
      },
      stationCenter: {
        lat: null,
        lng: null
      },
      zoom: 19,
      pageSaved: false
    };

    if (props.savedData) {
      Object.keys(uiState).forEach(key => {
        if (props.savedData[key]) {
          uiState[key] = props.savedData[key];
        }
      });
    }

    // Set the Center of the Map as the User's location on new Stations.
    if (!uiState.center.lat) {
      uiState.center.lat = props.global.userCoords.latitude;
      uiState.center.lng = props.global.userCoords.longitude;
    }

    this.state = {
      surroundingStations: [],
      data: {},
      isValidated: false,
      ...uiState
    };

    this.mapOnChange = this.mapOnChange.bind(this);
    this.onFormSubmit = this.onFormSubmit.bind(this);
    this.pageApiCall = this.pageApiCall.bind(this);
    this.isValidPosition = this.isValidPosition.bind(this);
  }

  mapOnChange({ center, zoom }) {
    this.setState({ center, zoom });
    fetchGET(
      `/installer/pinpointed_stations/${this.props.global.deviceId}?lat=${
        center.lat
      }&lon=${center.lng}`
    ).then(res => {
      this.setState({ surroundingStations: res });
    });
  }

  onFormSubmit() {
    if (this.isValidPosition()) {
      // Success on validation
      this.setState({
        displayError: false,
        isValidated: true
      });
    }
  }

  isValidPosition() {
    const { t } = this.props;
    const {
      MAX_DISTANCE_PINPOINT,
      isPreSaved,
      OVERRIDE_MAX_DISTANCE,
      MAX_DISTANCE_UNIT
    } = this.props.global;

    if (OVERRIDE_MAX_DISTANCE) return true;

    const distanceFromSearch = distanceBetweenCoordinates(
      this.props.global.userCoords.latitude,
      this.props.global.userCoords.longitude,
      this.state.center.lat,
      this.state.center.lng,
      MAX_DISTANCE_UNIT
    );

    if (distanceFromSearch > MAX_DISTANCE_PINPOINT) {
      // too far warning
      const errorMessage = t('pinpoint.map_too_far');
      this.setState({
        displayError: true,
        errorMessage,
        errFromValidation: true
      });
      return false;
    }

    if (distanceFromSearch <= 1 && !isPreSaved) {
      // didn't move
      const errorMessage = t('pinpoint.map_no_movement');
      this.setState({
        displayError: true,
        errorMessage,
        errFromValidation: true
      });
      return false;
    }

    return true;
  }

  pageApiCall() {
    return new Promise((resolve, reject) => {
      fetchAPI(`/installer/station_pinpoint/${this.props.global.deviceId}`, {
        station_lat: this.state.center.lat,
        station_lon: this.state.center.lng
      })
        .then(res => {
          if (!res.error) {
            this.setState(
              prevState => ({
                data: {
                  zoom: prevState.zoom,
                  center: prevState.center,
                  pageSaved: true
                }
              }),
              () => {
                resolve();
              }
            );
          } else {
            throw Error(res.message);
          }
        })
        .catch(err => {
          this.setState(
            {
              displayError: true,
              errorMessage: err.message,
              isValidated: false,
              errFromValidation: false
            },
            () => {
              reject();
            }
          );
        });
    });
  }

  render() {
    const { t } = this.props;
    return (
      <Fragment>
        <p>{t('pinpoint.paragraph_map')}</p>
        <FormValidation onSubmit={this.onFormSubmit}>
          <div id='map'>
            <div id='centeredlineY' />
            <GoogleMapReact
              defaultCenter={{
                lat: 59.95,
                lng: 30.33
              }}
              defaultZoom={5}
              zoom={this.state.zoom}
              center={this.state.center}
              onChange={this.mapOnChange}
              bootstrapURLKeys={getMapKeys}
              options={getMapOptions}
            >
              {this.state.surroundingStations.map(station => (
                <Marker key={station.id} lat={station.lat} lng={station.lon} />
              ))}

              <Marker
                lat={this.state.stationCenter.lat}
                lng={this.state.stationCenter.lng}
                searchIcon
              />
              <Marker
                lat={this.props.global.userCoords.latitude}
                lng={this.props.global.userCoords.longitude}
                userIcon
              />
            </GoogleMapReact>

            <div id='centeredlineX' />
            <Marker mainPin />
          </div>
          <Navigation
            data={this.state.data}
            isValidated={this.state.isValidated}
            apicall={this.pageApiCall}
          />
        </FormValidation>
        <ErrorMessage
          err={this.state.errorMessage}
          display={this.state.displayError}
          errFromValidation={this.state.errFromValidation}
        />
      </Fragment>
    );
  }
}

export default Pinpoint;
