import Bowser from 'bowser';

export const stepList = [
  'opensettings',
  'general',
  'reset',
  'passcode',
  'fullreset',
  'browser',
  'website',
  'allowloc',
  'ok'
];

const userBrowser = Bowser.getParser(window.navigator.userAgent);
export const isiOS = userBrowser.getOSName().toLowerCase() === 'ios';
export const isChromeAndroid =
  userBrowser.getBrowserName().toLowerCase() === 'chrome' &&
  userBrowser.getOSName().toLowerCase() === 'android';
