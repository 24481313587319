import React, { Component } from 'react';
import './style.css';

class Radio extends Component {
  constructor(props) {
    super(props);
    this.handleOptionChange = this.handleOptionChange.bind(this);
  }

  handleOptionChange(option) {
    this.props.onSelected(option);
  }

  render() {
    return (
      <div className='radio'>
        {this.props.labels.map((label, i) => (
          // eslint-disable-next-line react/no-array-index-key
          <label key={i} htmlFor={`${this.props.name}option${i}`}>
            <input
              type='radio'
              name={this.props.name ? this.props.name : 'radio'}
              id={`${this.props.name}option${i}`}
              value={label.key}
              checked={this.props.selectedOption === label.key}
              onChange={() => {
                this.handleOptionChange(label.key);
              }}
            />
            <p>{label.text}</p>
          </label>
        ))}
      </div>
    );
  }
}

export default Radio;
