import React, { Component, cloneElement } from 'react';
import { withRouter } from 'react-router-dom';
import { Translation } from 'react-i18next';
import Cookies from 'universal-cookie';
import i18n from 'i18next';
import DataFlowContext from './DataFlowContext';
import Subheader from '../Subheader';
import {
  formatDefaultMac,
  setPinpointData,
  setAddressFormData,
  wizardDefaultState
} from './helpers';
import { setPageData } from './setPageData';
import { setGlobalData } from './setGlobalData';
import { setNavPath, defaultNavPath } from './setNavPath';
import { mixpanelSubmit, setMixpanelFlow } from '../../utils/mixpanel';
import { fetchGET } from '../../Router/helpers';
import { getSupportedLocale, loadGoogleMapsForLanguage } from "../../utils/i18n";

const cookies = new Cookies();

class Wizard extends Component {
  constructor(props) {
    super(props);

    const savedData = {};
    if (props.macPrefillFromURL) {
      if (Object.keys(props.macPrefillFromURL).length > 0) {
        const { defaultmac, defaultmacpass, installtype } = formatDefaultMac(
          props.macPrefillFromURL
        );
        savedData['/'] = { defaultmac, defaultmacpass };
        if (installtype.length > 0) savedData['/installtype'] = { installtype };
      }
    }

    this.state = {
      ...wizardDefaultState,
      savedData
    };

    this.saveData = this.saveData.bind(this);
    this.navigate = this.navigate.bind(this);
    this.navPath = defaultNavPath;
    this.blockBackNav = false;
  }

  componentDidMount() {
    const currentLang = i18n.language;
    let prefLang = getSupportedLocale(cookies.get('locale'));

    if (prefLang !== currentLang) {
      i18n.changeLanguage(prefLang);
    }
    // Check if there is a mac available
    if (!this.state.globalData.mac) {
      this.props.history.push('/');
    }

    // get & set Config values
    fetchGET('/api/config').then(data => {
      const {
        MAX_DISTANCE_ADDRESS,
        MAX_DISTANCE_PINPOINT,
        OVERRIDE_MAX_DISTANCE,
        MAX_DISTANCE_UNIT
      } = data;

      this.setState(prevState => ({
        globalData: {
          ...prevState.globalData,
          MAX_DISTANCE_ADDRESS,
          MAX_DISTANCE_PINPOINT,
          OVERRIDE_MAX_DISTANCE,
          MAX_DISTANCE_UNIT
        }
      }));
    });

    loadGoogleMapsForLanguage(prefLang);
  }

  shouldComponentUpdate(nextProps) {
    const currentPropsLoc = this.props.location.pathname;
    const nextPropsLoc = nextProps.location.pathname;
    // if previous is not mac validation page and current one is mac validation page
    if (currentPropsLoc !== '/' && nextPropsLoc === '/') {
      this.navPath = defaultNavPath;
      this.setState(wizardDefaultState);
      this.blockBackNav = true;
    }

    // after a flow, don't render old pages from part of the flow
    if (this.blockBackNav && nextPropsLoc !== '/') {
      this.props.history.push('/');
    }

    return true;
  }

  saveData(dataToSave) {
    const currentPage = this.props.location.pathname;

    // setting global data
    const globalData = setGlobalData(this.state.globalData, dataToSave);

    // Presaving logic (After Mac Validation Page)
    let stateData = {};
    if (currentPage === '/') stateData = setPageData(dataToSave);

    // setting navpath
    this.navPath = setNavPath(
      this.navPath,
      globalData,
      dataToSave,
      this.state.savedData
    );

    let pinpointData = {};
    let addressForm = {};
    if (currentPage === '/address') {
      // passing data to pinpoint
      pinpointData = setPinpointData(
        this.state.savedData['/pinpoint'],
        dataToSave
      );
      addressForm = setAddressFormData(dataToSave);
    }

    // check if next page is last
    globalData.isLastPage =
      currentPage === this.navPath[this.navPath.length - 2];
    const pathnameKey = currentPage;

    // Mixpanel Events and Flow
    setMixpanelFlow(this.navPath, globalData);
    mixpanelSubmit(currentPage, dataToSave);

    this.setState(
      prevData => ({
        savedData: {
          ...prevData.savedData,
          [pathnameKey]: dataToSave,
          ...stateData,
          ...pinpointData,
          ...addressForm
        },
        globalData
      }),
      () => {
        this.navigate('next');
      }
    );
  }

  navigate(direction) {
    this.blockBackNav = false;
    const currentPath = this.props.location.pathname;
    const currentPathIndex = this.navPath.indexOf(currentPath);
    const navPath = this.navPath;
    if (direction === 'back') {
      this.props.history.push(navPath[currentPathIndex - 1]);
    }

    if (direction === 'next') {
      this.props.history.push(navPath[currentPathIndex + 1]);
    }
  }

  render() {
    return (
      <Translation>
        {t => (
          <DataFlowContext.Provider
            value={{
              saveData: this.saveData,
              navigate: this.navigate
            }}
          >
            <Subheader global={this.state.globalData} />
            {cloneElement(this.props.children, {
              global: this.state.globalData,
              savedData: this.state.savedData[this.props.location.pathname],
              t
            })}
          </DataFlowContext.Provider>
        )}
      </Translation>
    );
  }
}

export default withRouter(Wizard);
