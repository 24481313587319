import React from 'react';
import './style.css';
import { useTranslation } from 'react-i18next';

function Subheader(props) {
  const { t } = useTranslation();
  return (
    <div className='subheader'>
      <h2>{t('subheader.title_Chargepoint')}</h2>
      {props.global.mac ? (
        <p>{`MAC ${props.global.mac}`}</p>
      ) : (
        <p>{t('subheader.paragraph_intro')}</p>
      )}
    </div>
  );
}

export default Subheader;
