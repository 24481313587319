import React, { Component, Fragment, createRef } from 'react';
import GoogleMapReact from 'google-map-react';
import Marker from '../../Components/Marker';
import SearchBox from '../../Components/SearchBox';

import FormValidation from '../../Components/FormValidation';
import Navigation from '../../Components/Navigation';
import ErrorMessage from '../../Components/ErrorMessage';

import { getMapKeys, distanceBetweenCoordinates } from '../../utils/map';
import { fetchAPI } from '../../Router/helpers';

import {
  mapOptions,
  isFullAddress,
  getAddressObj,
  isNumberPresent
} from './helpers';

import {
  defaultAddressAndPosition,
  getAddressForServer
} from '../../utils/address';

import './style.css';

class AddressSearch extends Component {
  constructor(props) {
    super(props);
    const uiState = {
      searchBoxValue: '',
      pageSaved: false,
      ...defaultAddressAndPosition
    };

    if (props.savedData) {
      Object.keys(uiState).forEach(key => {
        if (props.savedData[key]) {
          uiState[key] = props.savedData[key];
        }
      });
    }

    this.state = {
      data: {},
      displayMap: !!uiState.searchBoxValue,
      isValidated: false,
      displayError: false,
      errorMessage: '',
      errFromValidation: false,
      showStreetWarning: false,
      ...uiState
    };

    this.searchRef = createRef();
    this.searchBoxHandleSubmit = this.searchBoxHandleSubmit.bind(this);
    this.searchBoxOnChange = this.searchBoxOnChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.pageApiCall = this.pageApiCall.bind(this);
    this.displayErrorAddress = this.displayErrorAddress.bind(this);
    this.isValidPosition = this.isValidPosition.bind(this);
  }

  searchBoxOnChange() {
    // Clean up if there are changes
    this.setState({
      displayError: false,
      displayMap: false,
      ...defaultAddressAndPosition
    });
  }

  searchBoxHandleSubmit(data) {
    // on Submit of SearchBox
    if (data.geometry) {
      // display map and store object
      const addressObj = getAddressObj(data);

      // set showstreetwarning
      const showStreetWarning = isNumberPresent(data);

      this.setState({
        displayMap: true,
        addressObj,
        center: {
          lat: data.geometry.location.lat(),
          lng: data.geometry.location.lng()
        },
        displayError: false,
        showStreetWarning
      });
    } else {
      this.displayErrorAddress();
    }
  }

  displayErrorAddress() {
    const errorMessage = this.props.t(
      'addresssearch.inputerror_search_required'
    );
    this.setState({
      displayError: true,
      errorMessage,
      errFromValidation: true
    });
  }

  onSubmit() {
    const { inputIsFilled, inputValue } = this.searchRef.current.state;
    const { addressObj, displayMap } = this.state;

    // Stage 1: where next = enter
    if (!displayMap) {
      // If everything is empty
      if (!inputIsFilled) {
        this.displayErrorAddress();
      } else {
        // If it's not empty do a geocall
        this.searchRef.current.onSelected();
      }
      return;
    }

    // If the address is not complete but was found by google (missing route)
    if (!isFullAddress(addressObj) && displayMap) {
      this.displayErrorAddress();
      return;
    }

    // Check for Validation when everything else passed
    if (this.isValidPosition()) {
      // SUCCESS
      this.setState({
        isValidated: true,
        addressObj,
        searchBoxValue: inputValue
      });
    }
  }

  isValidPosition() {
    const { t } = this.props;
    const {
      MAX_DISTANCE_ADDRESS,
      OVERRIDE_MAX_DISTANCE,
      MAX_DISTANCE_UNIT
    } = this.props.global;

    if (OVERRIDE_MAX_DISTANCE) return true;

    const distanceFromSearch = distanceBetweenCoordinates(
      this.props.global.userCoords.latitude,
      this.props.global.userCoords.longitude,
      this.state.center.lat,
      this.state.center.lng,
      MAX_DISTANCE_UNIT
    );

    if (distanceFromSearch > MAX_DISTANCE_ADDRESS) {
      // too far warning
      const errorMessage = t('addressform.user_too_far');
      this.setState({
        displayError: true,
        errorMessage,
        errFromValidation: true
      });
      return false;
    }

    return true;
  }

  pageApiCall() {
    return new Promise((resolve, reject) => {
      const formData = getAddressForServer(this.state);
      fetchAPI(
        `/installer/station_address/${this.props.global.deviceId}`,
        formData
      )
        .then(res => {
          if (!res.error) {
            this.setState(
              prevState => ({
                data: {
                  addressObj: prevState.addressObj,
                  center: prevState.center,
                  pageSaved: true,
                  searchBoxValue: prevState.searchBoxValue,
                  showStreetWarning: prevState.showStreetWarning
                }
              }),
              () => {
                resolve();
              }
            );
          } else {
            throw Error(res.message);
          }
        })
        .catch(err => {
          this.setState(
            {
              displayError: true,
              errorMessage: err.message,
              isValidated: false,
              errFromValidation: false
            },
            () => {
              reject();
            }
          );
        });
    });
  }

  render() {
    const { t } = this.props;
    return (
      <Fragment>
        <SearchBox
          global={this.props.global}
          onChange={this.searchBoxOnChange}
          ref={this.searchRef}
          types={['geocode']}
          defaultValue={this.state.searchBoxValue}
          onPlaceSelected={place => {
            this.searchBoxHandleSubmit(place);
          }}
          placeholder={t('addresssearch.input_searchbox')}
        />
        {this.state.displayMap && (
          <div id='map' className='addressMap'>
            <GoogleMapReact
              ma
              defaultCenter={{
                lat: this.props.global.userCoords.latitude,
                lng: this.props.global.userCoords.longitude
              }}
              defaultZoom={17}
              center={this.state.center}
              bootstrapURLKeys={getMapKeys}
              draggable={false}
              options={mapOptions}
            >
              <Marker
                searchIcon
                lat={this.state.center.lat}
                lng={this.state.center.lng}
              />
            </GoogleMapReact>
          </div>
        )}
        <FormValidation onSubmit={this.onSubmit}>
          <Navigation
            data={this.state.data}
            isValidated={this.state.isValidated}
            apicall={this.pageApiCall}
          />
        </FormValidation>

        <ErrorMessage
          err={this.state.errorMessage}
          display={this.state.displayError}
          errFromValidation={this.state.errFromValidation}
        />
      </Fragment>
    );
  }
}

export default AddressSearch;
