import React, { Component, Fragment } from 'react';
import SubmitContext from '../FormValidation/SubmitContext';
import './style.css';
import ErrorMessage from '../ErrorMessage';
import { getFile } from './helpers';

class InputFile extends Component {
  constructor(props) {
    super(props);
    const uiState = {
      fileValue: {},
      displayFile: false,
      fileImage: '',
      errorMessage: props.errorValidation || ''
    };

    if (props.fileImage.length > 0) {
      uiState.fileValue = props.fileValue;
      uiState.displayFile = true;
      uiState.fileImage = props.fileImage;
    }
    this.state = {
      displayError: false,
      ...uiState
    };
    this.fileRef = React.createRef();

    this.onChangeinputfile = this.onChangeinputfile.bind(this);
    this.validate = this.validate.bind(this);
    this.reset = this.reset.bind(this);
  }

  async componentDidMount() {
    this.context.provideValidateFunction(this.props.name, this.validate);
    this.context.provideResetFunction(this.props.name, this.reset);

    // get File if presaved
    const { fileImage, fileValue } = this.state;
    if (fileImage.length > 0 && !fileValue.name) {
      const newFileValue = await getFile(fileImage);
      this.setState({
        fileValue: newFileValue
      });
    }
  }

  componentWillUnmount() {
    this.context.unmountFunction(this);
  }

  onChangeinputfile(e) {
    const f = e.target.files[0];
    if (f && f.type.includes('image/')) {
      const reader = new FileReader();
      reader.onload = evt => {
        this.setState({ fileImage: evt.target.result });
      };
      reader.readAsDataURL(f);

      this.setState(
        {
          displayFile: true,
          fileImage: URL.createObjectURL(f),
          fileValue: f
        },
        () => {
          this.validate();
        }
      );
    } else {
      this.reset();
    }
  }

  validate() {
    let displayError;
    if (this.props.required) {
      displayError = this.state.fileImage.length === 0;
      this.setState({ displayError });
      const val = {};
      val[this.props.name] = !displayError;
      const inputValue = {
        fileValue: this.state.fileValue,
        fileImage: this.state.fileImage
      };
      this.context.provideValidationResult(val, inputValue);
    }
  }

  reset() {
    this.fileRef.current.value = '';
    this.setState({
      displayFile: false,
      fileImage: ''
    });
  }

  render() {
    return (
      <Fragment>
        {this.state.displayFile ? (
          <img
            className='imageFile'
            src={this.state.fileImage}
            alt={this.state.fileName}
          />
        ) : (
          <img
            src={this.props.placeholderImageFile}
            alt='camera'
            className='defaultImageFile'
          />
        )}

        <input
          ref={this.fileRef}
          type='file'
          name={this.props.name}
          id='file'
          className='inputfile'
          accept='image/*'
          onChange={this.onChangeinputfile}
          files={Array(1).push(this.state.fileValue)}
        />

        <label htmlFor='file'>
          {this.state.displayFile
            ? this.props.labelonUse
            : this.props.labelDefault}
        </label>
        <div className='center'>
          <ErrorMessage
            display={this.state.displayError}
            err={this.state.errorMessage}
            errFromValidation
          />
        </div>
      </Fragment>
    );
  }
}

InputFile.contextType = SubmitContext;
export default InputFile;
