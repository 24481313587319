import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Navigation from '../../Components/Navigation';

// eslint-disable-next-line react/prefer-stateless-function
class SuccessSwap extends Component {
  render() {
    const { t } = this.props;

    return (
      <React.Fragment>
        <div className="success-holder">
          <p>{t('successswap.paragraph_success')}</p>
          {this.props.global.isLastPage ? (
            <Link to='/' className='successbutton'>
              {t('successswap.link_startnew')}
            </Link>
          ) : (
            <Navigation data='Success' disablePrevButton />
          )}
        </div>
      </React.Fragment>
    );
  }
}

export default SuccessSwap;
