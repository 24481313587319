import React, { Component } from 'react';
import FormValidation from '../../Components/FormValidation';

import InputFile from '../../Components/InputFile';
import Navigation from '../../Components/Navigation';
import LoadingIndicator  from '../../Components/LoadingIndicator'
import { createFormData } from './helpers';

import './style.css';

import { fetchAPIFile } from '../../Router/helpers';
import ErrorMessage from '../../Components/ErrorMessage';

class TerminalBlock extends Component {
  constructor(props) {
    super(props);
    const uiState = {
      fileValue: {},
      fileImage: '',
      savingTerminalBlockImage: false
    };

    if (props.savedData) {
      Object.keys(uiState).forEach(key => {
        if (props.savedData[key]) {
          uiState[key] = props.savedData[key];
        }
      });
    }

    this.state = {
      isValidated: false,
      data: {},
      ...uiState
    };

    this.pageApiCall = this.pageApiCall.bind(this);
    this.onFormSubmit = this.onFormSubmit.bind(this);
  }

  onFormSubmit(data) {
    this.setState({
      displayError: false,
      isValidated: true,
      fileValue: data.imageFile.fileValue,
      fileImage: data.imageFile.fileImage
    });
  }

  pageApiCall() {
    return new Promise((resolve, reject) => {
      this.setState({
        savingTerminalBlockImage: true
      })
      const formData = createFormData(this.state);
      fetchAPIFile(
        `/installer/terminal_block_photo/${this.props.global.deviceId}`,
        formData
      )
      .then(res => {
        if (!res.error) {
          this.setState(
            prevState => ({
              data: {
                fileValue: prevState.fileValue,
                fileImage: prevState.fileImage,
                isPreSaved: prevState.isPreSaved
              },
              savingTerminalBlockImage: false
            }),
            () => {
              resolve();
            }
          );
        } else {
          throw Error(res.message);
        }
      })
      .catch(err => {
        this.setState(
          {
            displayError: true,
            errorMessage: err.message,
            isValidated: false,
            errFromValidation: false,
            savingTerminalBlockImage: false
          },
          () => {
            reject();
          }
        );
      });
    })
  }

  render() {
    const { t } = this.props;

    return (
      <div className='terminal-block'>
        <FormValidation onSubmit={this.onFormSubmit}>
          <h3 className='terminalblock-title-photo'>{`${t('terminalblock.title_photo')}*`}</h3>
          <p className='terminalblock-paragraph-photo'>{t('terminalblock.paragraph_photo')}</p>

          <InputFile
            required
            name='imageFile'
            fileValue={this.state.fileValue}
            fileImage={this.state.fileImage}
            errorValidation={t('terminalblock.inputfile_photo_required')}
            labelDefault={t('terminalblock.inputfile_attachphoto')}
            labelonUse={t('terminalblock.inputfile_reattachphoto')}
            placeholderImageFile={this.props.global.isOCPP ?
                                  '/images/ic_alfen_block_2_phase.svg' :
                                  '/images/ic_terminal_block_cp.svg'}
          />

          <LoadingIndicator
            size={50}
            show={this.state.savingTerminalBlockImage}/>

          <Navigation
            apicall={this.pageApiCall}
            data={this.state.data}
            isValidated={this.state.isValidated}
          />
        </FormValidation>
        <ErrorMessage
          err={this.state.errorMessage}
          display={this.state.displayError}
          errFromValidation={this.state.errFromValidation}
        />
      </div>
    );
  }
}

export default TerminalBlock;
