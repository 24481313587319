import React from 'react';
import { Loader } from '@chargepoint/cp-toolkit'
import PropTypes from 'prop-types'

const LoadingIndicator = props => {
    if (props.show) {
        return (
            <div style={{position: 'relative', height: props.size}}>
                <Loader size={props.size}/>
            </div>
        )
    }
    return null
}

LoadingIndicator.propTypes = {
    show: PropTypes.bool, // This property determines if the LoadingIndicator is shown or not.
    size: PropTypes.number // This property determines the size of the LoadingIndicator.
}

LoadingIndicator.defaultProps = {
    show: false, // By default, LoadingIndicator will not be shown.
    size: 100 // By default, LoadingIndicator will be shown at full size.
}

export default LoadingIndicator
