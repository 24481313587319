/* eslint-disable react/jsx-one-expression-per-line */
import React, { Fragment, Component } from 'react';
import { Trans } from 'react-i18next';
import ErrorMessage from '../../Components/ErrorMessage';
import { stepList, isiOS, isChromeAndroid } from './helpers';
import Navigation from '../../Components/Navigation';

class LocationReq extends Component {
  constructor() {
    super();
    this.state = {
      displayError: false,
      data: {
        userLat: 0,
        userLon: 0
      }
    };

    this.getUserLocation = this.getUserLocation.bind(this);
  }

  getUserLocation() {
    return new Promise((resolve, reject) => {
      navigator.geolocation.getCurrentPosition(
        pos => {
          const data = {
            userLat: pos.coords.latitude,
            userLon: pos.coords.longitude
          };
          this.setState(
            {
              data
            },
            () => {
              resolve();
            }
          );
        },
        () => {
          this.setState(
            {
              displayError: true
            },
            () => {
              reject();
            }
          );
        },
        {
          enableHighAccuracy: true,
          timeout: 10000,
          maximumAge: 0
        }
      );
    });
  }

  render() {
    const { t } = this.props;

    let instructions = <p>{t('locationReq.paragraph_general_instructions')}</p>;
    if (isChromeAndroid)
      instructions = (
        <p>
          <Trans i18nKey='locationReq.paragraph_android_instructions'>
            Follow the instructions for changing settings for a site{' '}
            <a
              href='https://support.google.com/chrome/answer/114662'
              target='_blank'
              rel='noopener noreferrer'
            >
              here
            </a>
            .
          </Trans>
        </p>
      );

    if (isiOS)
      instructions = (
        <ul>
          {stepList.map((x, i) => (
            <li key={i}>{t(`locationReq.list_step${i}_${x}`)}</li>
          ))}
        </ul>
      );

    return (
      <Fragment>
        <h3>{t('locationReq.title_loc')}</h3>
        <p>{t('locationReq.paragraph_intro')}</p>

        {this.state.displayError ? (
          <Fragment>
            <ErrorMessage
              display
              errFromValidation
              err='locationReq.paragraph_err'
            />
            <h3>{t('locationReq.title_err')}</h3>

            {instructions}
          </Fragment>
        ) : (
          <Navigation
            data={this.state.data}
            apicall={this.getUserLocation}
            disablePrevButton
            overrideNextText={t('locationReq.button_relocate')}
          />
        )}
      </Fragment>
    );
  }
}

export default LocationReq;
