import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import Auth from './Auth';
import Wizard from '../Components/Wizard';

function PrivateRoute({ component: Component, macPrefillFromURL, ...rest }) {
  return (
    <Route
      {...rest}
      render={props =>
        Auth.isAuthenticated ? (
          <Wizard macPrefillFromURL={macPrefillFromURL}>
            <Component {...props} />
          </Wizard>
        ) : (
          <Redirect
            to={{
              pathname: '/login',
              state: { from: '/' }
            }}
          />
        )
      }
    />
  );
}

export default PrivateRoute;
