import React, { Component, Suspense } from 'react';
import Router from './Router';
import Auth from './Router/Auth';
import { Loader } from '@chargepoint/cp-toolkit'

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isInitialized: false
    };
  }

  componentDidMount() {
    Auth.isSSOEnabled().then(useSSO => {
      Auth.validateSession()
        .then(response => {
          if (!response.ok) {
            throw response.statusText;
          }
          return response.json();
        })
        .then(result => {
          if (result.is_user_valid) {
            Auth.autoSignin(result.full_name);
          } else {
            if (result.error) {
              Auth.errorMessage = result.message;
            }
            throw new Error('User or Password is invalid');
          }
        })
        .catch(() => {
          Auth.signout();
        })
        .finally(() => {
          this.setState({
            isInitialized: true
          });
        })
    });
  }

  render() {
    return (
      <Suspense fallback={<Loader />}>
        <div className='App'>
          {this.state.isInitialized ? <Router initialized={this.state.isInitialized} /> : <Loader />}
        </div>
      </Suspense>
    );
  }
}

export default App;
