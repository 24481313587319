import Cookies from 'universal-cookie';
import Auth from './Auth';

function getCSRFCookie() {
  const cookies = new Cookies();
  return cookies.get('csrftoken', { path: '/' });
}

export function fetchAPI(url, params) {
  return new Promise((resolve, reject) => {
    fetch(url, {
      method: 'POST',
      body: JSON.stringify(params),
      headers: {
        'Content-Type': 'application/json',
        'X-Frame-Options': 'sameorigin',
        'X-CSRFToken': getCSRFCookie()
      },
      credentials: 'include'
    })
      .then(res => {
        if (res.status === 403) {
          Auth.signout(true);
          throw Error();
        }
        if (res.status >= 500) {
          throw res.statusText;
        }
        return res.json();
      })
      .then(res => {
        if (res.error) {
          if (res.message === 'SOMETHING_WENT_WRONG' && res.message_text && res.message_text !== '') {
            throw Error(res.message_text);
          }
          throw Error(res.message);
        }
        resolve(res);
      })
      .catch(err => {
        reject(err);
      });
  });
}

export function fetchGET(url) {
  return new Promise((resolve, reject) => {
    fetch(url, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'X-Frame-Options': 'sameorigin',
        'X-CSRFToken': getCSRFCookie()
      },
      credentials: 'include'
    })
      .then(res => {
        if (res.status === 403) {
          Auth.signout(true);
          throw Error();
        }
        if (res.status >= 500) {
          throw res.statusText;
        }
        return res.json();
      })
      .then(res => {
        if (res.error) {
          throw Error(res.message);
        }
        resolve(res);
      })
      .catch(err => {
        reject(err);
      });
  });
}

export function fetchAPIFile(url, params) {
  return new Promise((resolve, reject) => {
    fetch(url, {
      method: 'POST',
      body: params,
      headers: {
        'X-Frame-Options': 'sameorigin',
        'X-CSRFToken': getCSRFCookie()
      },
      credentials: 'include'
    })
      .then(res => {
        if (res.status === 403) {
          Auth.signout(true);
          throw Error();
        }
        if (res.status >= 500) {
          throw res.statusText;
        }
        return res.json();
      })
      .then(res => {
        if (res.error) {
          throw Error(res.message);
        }
        resolve(res);
      })
      .catch(err => {
        reject(err);
      });
  });
}
