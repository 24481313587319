export function setGlobalData(global, incomingData) {
  const globalData = global;
  // initiate and don't change if new data comes in and it is presaved
  globalData.isPreSaved = global.isPreSaved ? global.isPreSaved : false;

  Object.entries(incomingData).forEach(entry => {
    const value = entry[1];
    switch (entry[0]) {
      case 'deviceId':
        globalData.deviceId = value;
        break;
      case 'station_lat':
        globalData.isPreSaved = !!value;
        break;
      case 'mac':
        globalData.mac = value;
        break;
      case 'makeName':
        globalData.makeName = value;
        break;
      case 'make_name':
        globalData.makeName = value;
        break;
      case 'modelName':
        globalData.modelName = value;
        break;
      case 'model_number':
        globalData.modelName = value;
        break;
      case 'is_ocpp_onramp_station':
        globalData.isOCPP = value;
        break;
      case 'installtype':
        globalData.installtype = value;
        break;
      case 'network_hardware_only_swap':
        globalData.networkHardwareSwap = value;
        break;
      case 'is_three_phase_station':
        globalData.isThreePhase = value;
        break;
      case 'input_phase_capability':
        globalData.inputPhaseCapability = value;
        break;
      case 'userLat':
        globalData.userCoords.latitude = value;
        break;
      case 'userLon':
        globalData.userCoords.longitude = value;
        break;
      case 'is_alb_supported':
        globalData.isAlbSupported = value;
        break;
      default:
        break;
    }
  });
  return globalData;
}
