import React, { Component, Fragment } from 'react';
import Navigation from '../../Components/Navigation';
import FormValidation from '../../Components/FormValidation';
import Input from '../../Components/Input';
import ErrorMessage from '../../Components/ErrorMessage';
import { fetchAPI } from '../../Router/helpers';
import { macFormatting } from './helpers';
import './style.css';

class MacActivation extends Component {
  constructor(props) {
    super(props);
    const defaultMac = {
      mac: '',
      macpass: ''
    };

    if (props.savedData) {
      defaultMac.mac = props.savedData.defaultmac;
      defaultMac.macpass = props.savedData.defaultmacpass;
    }
    this.state = {
      ...defaultMac,
      data: {},
      isValidated: false,
      displayError: false,
      errorMessage: ''
    };
    this.formValidationOutput = this.formValidationOutput.bind(this);
    this.pageApiCall = this.pageApiCall.bind(this);
  }

  formValidationOutput(data) {
    this.setState({ isValidated: true, data });
  }

  pageApiCall() {
    return new Promise((resolve, reject) => {
      fetchAPI('/installer/validate_mac_address', {
        mac_address: this.state.data.mac,
        password: this.state.data.password
      })
        .then(res => {
          if (res.validMac) {
            this.setState(
              prevState => ({
                data: {
                  mac: macFormatting(prevState.data.mac),
                  password: prevState.data.password,
                  ...res
                }
              }),
              () => {
                resolve();
              }
            );
          } else {
            throw Error(res.err_type);
          }
        })
        .catch(err => {
          this.setState(
            {
              displayError: true,
              errorMessage: err.message,
              isValidated: false
            },
            () => {
              reject();
            }
          );
        });
    });
  }

  render() {
    const { t } = this.props;

    return (
      <Fragment>
        <FormValidation onSubmit={this.formValidationOutput}>
          <Input
            placeholder={t('macactivation.input_macid')}
            type='text'
            name='mac'
            validationRules={[
              {
                rule: 'required',
                err: t('macactivation.inputerror_macid_required')
              }
            ]}
            value={this.state.mac}
          />
          <Input
            placeholder={t('macactivation.input_password')}
            type='text'
            name='password'
            validationRules={[
              {
                rule: 'required',
                err: t('macactivation.inputerror_password_required')
              }
            ]}
            value={this.state.macpass}
          />
          <Navigation
            isValidated={this.state.isValidated}
            data={this.state.data}
            disablePrevButton
            apicall={this.pageApiCall}
          />
        </FormValidation>
        <ErrorMessage
          display={this.state.displayError}
          err={this.state.errorMessage}
        />
      </Fragment>
    );
  }
}

export default MacActivation;
