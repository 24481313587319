import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import './style.css';

// eslint-disable-next-line react/prefer-stateless-function
class Success extends Component {
  render() {
    const { t } = this.props;
    return (
      <React.Fragment>
        <div className='success-holder'>
          <p>{t('success.paragraph_complete')}</p>

          <img src='/images/ic_accepted.svg' alt='success logo' />

          <Link to='/' className='successbutton'>
            {t('success.link_startnew')}
          </Link>
        </div>
      </React.Fragment>
    );
  }
}

export default Success;
